import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Fab from "@material-ui/core/Fab";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography } from "antd";

const useStyles = makeStyles(theme => ({
    fabProgress: {
        color: "#607D8B",
        position: "relative",
        left: "-62px",
        top: "-6px",
        zIndex: 1,
    },

    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        backgroundColor: "#64B5F6",
    }
}));

export default function AqProgress() {
    const classes = useStyles();
    return (
        <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="center"
            p={1}
            m={3}
            bgcolor="background.paper"
        >
            <br></br>
            <Fab aria-label="save" color="primary">

                <Avatar aria-label="home" className={classes.avatar}>
                    <img
                        src="/img/askquran-blue.svg"
                        alt="Arabic Through Stories"
                        style={{
                            marginTop: "9px",
                            marginLeft: "5px",
                            width: "45px",
                            height: "60px",
                            padding: "3px",
                        }}
                    />
                </Avatar>
            </Fab>
            <div style={{ position: 'relative' }}>
                <Typography variant="button" display="block" gutterBottom
                    style={{ position: 'absolute', top: '65px', left: '-65px' }}>LOADING..</Typography>
                <CircularProgress size={68} className={classes.fabProgress} />
            </div>



        </Box>
    );
}
