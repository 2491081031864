import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";
import { Container, Draggable } from "react-smooth-dnd";
import arrayMove from "array-move";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DoneOutlineOutlinedIcon from "@material-ui/icons/DoneOutlineOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  optionsTxtFld: {
    fontSize: "large",
    lineHeight: "2rem",
    color: "#666666",
    border: "0px",
  },
}));

function SentenceEditor({ sentence, onEdit, num }) {
  const classes = useStyles();

  function callback(e) {
    onEdit(e.target.value, num);
  }

  return (
    <TextField
      defaultValue={sentence.line}
      multiline
      fullWidth
      rows={3}
      onChange={(e) => callback(e)}
      InputProps={{
        className: classes.optionsTxtFld,
        disableUnderline: true,
      }}
    />
  );
}

export default function FBOptionsEditor({ fbSentences }) {
  const classes = useStyles();

  const [selWordIndx, setSelWordIndx] = useState();
  const [selSentenceIndx, setSelSentenceIndx] = useState();
  const [selWordOptions, setSelWordOptions] = useState();
  const [selWord, setSelWord] = useState("");
  const [sentences, setSentences] = useState(fbSentences);
  const [editSentence, setEditSentence] = useState(false);
  var newSentenceVal, newSentenceIndex;

  const onDrop = ({ removedIndex, addedIndex }) => {
    var newArray = arrayMove(sentences, removedIndex, addedIndex);
    setSentences(newArray);
  };

  function updateWordOptions(e) {
    var opts = e.target.value.split(/\n/).filter(function (v, i) {
      if (v && v.trim().length > 0) return v;
    });
    let newArr = [...sentences];
    var sentenceOpts = newArr[selSentenceIndx].options;
    if (!sentenceOpts) {
      sentenceOpts = {};
    }
    sentenceOpts[selWordIndx] = opts;
    setSentences(newArr);
  }

  function displayOptions(e, sentenceIndx, wordIndx, word) {
    setSelSentenceIndx(sentenceIndx);
    setSelWordIndx(wordIndx);
    setSelWord(word);
    let newArr = [...sentences];
    var sentenceOpts = newArr[sentenceIndx].options;
    var wordOpts = sentenceOpts[wordIndx];
    if (!wordOpts) {
      wordOpts = [];
    }
    setSelWordOptions(wordOpts.join("\n"));
  }

  function changeSentence() {
    newSentenceVal = null;
    newSentenceIndex = -1;
    setEditSentence(true);
  }

  function finsihSentenceUpdate() {
    if (newSentenceIndex > -1) {
      let newArr = [...sentences];
      newArr[newSentenceIndex].line = newSentenceVal;
      setSentences(newArr);
    }
    setEditSentence(false);
  }

  function updateSentence(s, a) {
    newSentenceVal = s;
    newSentenceIndex = a;
  }

  return (
    <Grid container spacing={0}>
      <Grid item xs={8}>
        <Container
          dragHandleSelector=".drag-handle"
          lockAxis="y"
          onDrop={onDrop}
        >
          {sentences.map(function (sentence, s) {
            var newStr = sentence.line.replace(/  +/g, " ");
            var words = newStr.split(" ").map(function (item, index) {
              return item.trim();
            });

            var line = words.map(function (w, i) {
              var key = "w_" + i;

              return (
                <Typography
                  style={{
                    fontSize: "x-large",
                    lineHeight: "2.6rem",
                    padding: "1px",
                    margin: "1px",

                    whiteSpace: "nowrap",
                  }}
                  display="inline"
                  onClick={(x) => displayOptions(x, s, i, w)}
                  key={key}
                >
                  {w}
                </Typography>
              );
            });

            return (
              <Draggable key={"s_" + s}>
                <div
                  style={{
                    overflowWrap: "anywhere",
                    backgroundColor: "#F1F1F1",
                    padding: "15px",
                    margin: "15px",
                    borderRadius: "15px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "block",
                      color: "#888888",
                    }}
                    className="drag-handle"
                  >
                    <DragHandleIcon className="drag-handle" />
                    <CancelOutlinedIcon
                      style={{ float: "right", marginLeft: "25px" }}
                    />
                    {editSentence ? (
                      <DoneOutlineOutlinedIcon
                        onClick={finsihSentenceUpdate}
                        style={{ float: "right", marginLeft: "25px" }}
                      />
                    ) : (
                      <CreateOutlinedIcon
                        onClick={changeSentence}
                        style={{ float: "right", marginLeft: "25px" }}
                      />
                    )}
                  </div>
                  {editSentence ? (
                    <SentenceEditor
                      sentence={sentence}
                      onEdit={updateSentence}
                      num={s}
                    ></SentenceEditor>
                  ) : (
                    line
                  )}
                </div>
              </Draggable>
            );
          })}
        </Container>
      </Grid>
      <Grid item xs={4} style={{ padding: "15px" }}>
        <TextField
          id="outlined-multiline-static"
          InputProps={{
            className: classes.optionsTxtFld,
          }}
          label={selWordIndx + ":" + selWord}
          multiline
          fullWidth
          rows={8}
          InputLabelProps={{ shrink: true }}
          defaultValue={selWordOptions}
          variant="outlined"
          onChange={(e) => updateWordOptions(e)}
          helperText="Click on a word in the text to enter its options"
        />
      </Grid>
    </Grid>
  );
}
