import React from "react";
import Typography from "@material-ui/core/Typography";
import MuiLink from "./MuiLink";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
export default function AtsBreadCrumbs(props) {
  return (
    <Breadcrumbs
      maxItems={4}
      separator={<NavigateNextIcon fontSize="small" />}
      style={{ paddingLeft: "25px", paddingRight: "15px" }}
      aria-label="breadcrumb"
    >
      {props.crumbs.map(function (v, i) {
        return <MuiLink key={"br" + i} text={v.title} link={v.link}></MuiLink>;
      })}
      <Typography style={{ color: props.lastcolor }}>{props.last}</Typography>
    </Breadcrumbs>
  );
}
