import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import ReactGA from "react-ga";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import Assignment from "./Components/Assignments/Assignment";
import AssignmentEditor from "./Components/Authoring/AssignmentEditor";
import MarkupItemEditor from "./Components/Authoring/MarkupItemEditor";
import ItemList from "./Components/Authoring/ItemList";
import McmaEditor from "./Components/Authoring/McmaEditor";
import MFBItemEditor from "./Components/Authoring/MFBItemEditor";
import IraabEditor from "./Components/Authoring/IraabEditor";
import RankingEditor from "./Components/Authoring/RankingEditor";
import ReArrangeEditor from "./Components/Authoring/ReArrangeEditor";
import MatchEditor from "./Components/Authoring/MatchEditor";
import Authorized from "./Components/Authorized";
import PageEditor from "./Components/Authoring/PageEditor";


const AlfaazHome = lazy(() => import("./Components/Alfaaz/AlfaazHome"));
const Activity = lazy(() => import("./Components/Activities/Activity"));
const Lessons = lazy(() => import("./Components/Lessons"));
const Header = lazy(() => import("./Components/Layouts/Header"));
const Home = lazy(() => import("./Components/Home"));
const Modules = lazy(() => import("./Components/Modules"));
const Courses = lazy(() => import("./Components/Courses"));
const CourseGradesPage = lazy(() => import("./Components/CourseGradesPage"));
const OpenGameroom = lazy(() => import("./Components/Gaming/OpenGameroom"));
const Gameroom = lazy(() => import("./Components/Gaming/Gameroom"));
const WatchGame = lazy(() => import("./Components/Gaming/WatchGame"));

const SignIn = lazy(() => import("./Components/SignIn"));
const SignUp = lazy(() => import("./Components/SignUp"));
const SignOut = lazy(() => import("./Components/SignOut"));
const ForgotPwd = lazy(() => import("./Components/ForgotPwd"));
const ResetPwd = lazy(() => import("./Components/ResetPwd"));
const ConfirmEmail = lazy(() => import("./Components/ConfirmEmail"));
const StudentSkillcheck = lazy(() => import("./Components/StudentSkillcheck"));
const AssignmentsDue = lazy(() => import("./Components/AssignmentsDue"));
const CourseMembers = lazy(() => import("./Components/User/CourseMembers"));
const AssignmentsMissing = lazy(() =>
  import("./Components/AssignmentsMissing")
);
const AssignmentsToGrade = lazy(() =>
  import("./Components/AssignmentsToGrade")
);
const CourseSkillcheck = lazy(() => import("./Components/CourseSkillcheck"));
const Reader = lazy(() => import("./Components/Reader"));
const AuthorContent = lazy(() =>
  import("./Components/Authoring/AuthorContent")
);
const ItemBanks = lazy(() => import("./Components/Authoring/ItemBanks"));
const JoinCourse = lazy(() => import("./Components/JoinCourse"));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ff6e40",
    },
    secondary: {
      main: "#ff7043",
    },
    background: {
      default: "#FFFFFF",
    },
  },
});

ReactGA.initialize("UA-65368312-2");
ReactGA.pageview("/home");

function ShowLoader() {
  const loaderStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      alignItems: "center",
      marginTop: "25px",
    },
    wrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
    fabProgress: {
      color: "#607D8B",
      position: "relative",
      left: "-62px",
      top: "-6px",
      zIndex: 1,
    },

    avatar: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      backgroundColor: "#64B5F6",
    },
  }));
  const classes = loaderStyles();

  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      justifyContent="center"
      p={1}
      m={3}
      bgcolor="background.paper"
    >
      <Fab aria-label="save" color="primary">
        <Avatar aria-label="home" className={classes.avatar}>
          <img
            src="/img/askquran-blue.svg"
            alt="Arabic Through Stories"
            style={{
              marginTop: "9px",
              marginLeft: "5px",
              width: "45px",
              height: "60px",
              padding: "3px",
            }}
          />
        </Avatar>
      </Fab>
      <CircularProgress size={68} className={classes.fabProgress} />
    </Box>
  );
}

export default function PrimarySearchAppBar() {
  let darktheme = "#252c2f";
  let lighttheme = "#FFFFFF";

  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <Suspense fallback={<ShowLoader></ShowLoader>}>
          <Switch>
            <Route exact path="/">
              <Header appbgcolor={lighttheme}></Header>
              <Home />
            </Route>
            <Route path="/OpenGameroom/course/:courseId/module/:moduleId/chapter/:chapterId/game/:contentId">
              <Header selectedtab="Gameroom" appbgcolor={darktheme}></Header>
              <OpenGameroom></OpenGameroom>
            </Route>
            <Route path="/Gameroom">
              <Header selectedtab="Gameroom" appbgcolor={darktheme}></Header>
              <Gameroom></Gameroom>
            </Route>
            <Route path="/WatchGame/:gamecode">
              <Header selectedtab="Gameroom" appbgcolor={darktheme}></Header>
              <WatchGame></WatchGame>
            </Route>
            <Route path="/Courses">
              <Authorized refRoute="Courses">
                <Header selectedtab="Courses" appbgcolor={lighttheme}></Header>
                <Courses></Courses>
              </Authorized>
            </Route>
            <Route path="/Reader/course/:courseId/module/:moduleId/chapter/:chapterId/content/:contentId">
              <Header selectedtab="Courses" appbgcolor={lighttheme}></Header>
              <Reader></Reader>
            </Route>
            <Route path="/Assignment/course/:courseId/module/:moduleId/chapter/:chapterId/lesson/:contentId">
              <Header selectedtab="Courses" appbgcolor={lighttheme}></Header>
              <Assignment></Assignment>
            </Route>
            <Route path="/Members/Course/:courseId">
              <Header selectedtab="Courses" appbgcolor={lighttheme}></Header>
              <CourseMembers></CourseMembers>
            </Route>
            <Route path="/Modules/Course/:courseId">
              <Header selectedtab="Courses" appbgcolor={lighttheme}></Header>
              <Modules></Modules>
            </Route>
            <Route path="/Lessons/:courseId/Module/:moduleId">
              <Header selectedtab="Courses" appbgcolor={lighttheme}></Header>
              <Lessons></Lessons>
            </Route>
            <Route path="/Lesson/:contentId">
              <Header selectedtab="Courses" appbgcolor={lighttheme}></Header>
              {/* <Lesson></Lesson> */}
            </Route>
            <Route path="/Activity/course/:courseId/module/:moduleId/chapter/:chapterId/content/:contentId">
              <Activity></Activity>
            </Route>
            <Route path="/Game/:gameCode/course/:courseId/module/:moduleId/chapter/:chapterId/content/:contentId">
              <Activity></Activity>
            </Route>
            {/* <Route path="/Discuss/:gameCode/:courseId/:contentId">
            <Discussion></Discussion>
          </Route> */}
            <Route path="/AssignmentsDue">
              <Header selectedtab="Courses" appbgcolor={lighttheme}></Header>
              <AssignmentsDue></AssignmentsDue>
            </Route>
            <Route path="/Grade/course/:courseId">
              <Header selectedtab="Courses" appbgcolor={lighttheme}></Header>
              <AssignmentsToGrade></AssignmentsToGrade>
            </Route>
            <Route path="/Assignment/missing/course/:courseId">
              <Header selectedtab="Courses" appbgcolor={lighttheme}></Header>
              <AssignmentsMissing></AssignmentsMissing>
            </Route>
            <Route path="/SkillCheck">
              <Header selectedtab="SkillCheck" appbgcolor={lighttheme}></Header>
              <StudentSkillcheck></StudentSkillcheck>
            </Route>
            <Route path="/CourseSkillCheck/:courseId">
              <Header selectedtab="Courses" appbgcolor={lighttheme}></Header>
              <CourseSkillcheck></CourseSkillcheck>
            </Route>
            <Route path="/coursegrades/:courseId">
              <Header selectedtab="Grades" appbgcolor={lighttheme}></Header>
              <CourseGradesPage></CourseGradesPage>
            </Route>
            <Route exact path="/quran/flashcards">
              <AlfaazHome></AlfaazHome>
            </Route>
            <Route exact path="/quran/flashcards/:num/:auto?">
              <AlfaazHome></AlfaazHome>
            </Route>
            <Route path="/Signup">
              <Header selectedtab="Signin" appbgcolor={lighttheme}></Header>
              <SignUp></SignUp>
            </Route>
            <Route path="/Signin">
              <Header selectedtab="Signin" appbgcolor={lighttheme}></Header>
              <SignIn></SignIn>
            </Route>
            <Route path="/Signout">
              <Header selectedtab="Signin" appbgcolor={lighttheme}></Header>
              <SignOut></SignOut>
            </Route>
            <Route path="/ForgotPwd">
              <Header selectedtab="Signin" appbgcolor={lighttheme}></Header>
              <ForgotPwd></ForgotPwd>
            </Route>
            <Route path="/JoinCourse">
              <Header selectedtab="Courses" appbgcolor={lighttheme}></Header>
              <JoinCourse></JoinCourse>
            </Route>
            <Route path="/ResetPwd/:token">
              <Header selectedtab="Signin" appbgcolor={lighttheme}></Header>
              <ResetPwd></ResetPwd>
            </Route>
            <Route path="/ConfirmEmail/:token">
              <Header selectedtab="Signin" appbgcolor={lighttheme}></Header>
              <ConfirmEmail></ConfirmEmail>
            </Route>
            {/*Authroing Routes  */}
            <Route path="/Authoring/lesson/course/:courseid/module/:modid/chapter/:chapid/lesson/:id">
              <Header appbgcolor={lighttheme}></Header>
              <PageEditor></PageEditor>
            </Route>
            {/* <Route path="/Authoring/assignment/course/:courseid/Module/:modcid"> */}
            <Route path="/Authoring/assignment/course/:courseid/module/:modcid/chapter/:chapId/lesson/:lsnId">
              <Header appbgcolor={lighttheme}></Header>
              <AssignmentEditor></AssignmentEditor>
            </Route>
            <Route path="/Authoring/assignment/:id/course/:courseid/Module/:modcid">
              <Header appbgcolor={lighttheme}></Header>
              <AssignmentEditor></AssignmentEditor>
            </Route>
            <Route path="/Authoring/itembanks">
              <Header selectedtab="Authoring" appbgcolor={lighttheme}></Header>
              <ItemBanks></ItemBanks>
            </Route>
            <Route path="/Authoring/itembank/:bankid">
              <Header selectedtab="Authoring" appbgcolor={lighttheme}></Header>
              <ItemList></ItemList>
            </Route>
            <Route exact path="/Authoring/itemtype/mfb/bank/:bankid">
              <Header selectedtab="Authoring" appbgcolor={lighttheme}></Header>
              <MFBItemEditor></MFBItemEditor>
            </Route>
            <Route
              exact
              path="/Authoring/itemtype/mfb/bank/:bankid/item/:itemid"
            >
              <Header selectedtab="Authoring" appbgcolor={lighttheme}></Header>
              <MFBItemEditor></MFBItemEditor>
            </Route>
            <Route exact path="/Authoring/itemtype/markup/bank/:bankid">
              <Header selectedtab="Authoring" appbgcolor={lighttheme}></Header>
              <MarkupItemEditor></MarkupItemEditor>
            </Route>
            <Route
              exact
              path="/Authoring/itemtype/markup/bank/:bankid/item/:itemid"
            >
              <Header selectedtab="Authoring" appbgcolor={lighttheme}></Header>
              <MarkupItemEditor></MarkupItemEditor>
            </Route>
            <Route exact path="/Authoring/itemtype/mcma/bank/:bankid">
              <Header selectedtab="Authoring" appbgcolor={lighttheme}></Header>
              <McmaEditor></McmaEditor>
            </Route>
            <Route
              exact
              path="/Authoring/itemtype/mcma/bank/:bankid/item/:itemid"
            >
              <Header selectedtab="Authoring" appbgcolor={lighttheme}></Header>

              <McmaEditor></McmaEditor>
            </Route>
            <Route exact path="/Authoring/itemtype/iraab/bank/:bankid">
              <Header selectedtab="Authoring" appbgcolor={lighttheme}></Header>
              <IraabEditor></IraabEditor>
            </Route>
            <Route
              exact
              path="/Authoring/itemtype/iraab/bank/:bankid/item/:itemid"
            >
              <Header selectedtab="Authoring" appbgcolor={lighttheme}></Header>
              <IraabEditor></IraabEditor>
            </Route>
            <Route exact path="/Authoring/itemtype/ranking/bank/:bankid">
              <Header selectedtab="Authoring" appbgcolor={lighttheme}></Header>
              <RankingEditor></RankingEditor>
            </Route>
            <Route
              exact
              path="/Authoring/itemtype/ranking/bank/:bankid/item/:itemid"
            >
              <Header selectedtab="Authoring" appbgcolor={lighttheme}></Header>
              <RankingEditor></RankingEditor>
            </Route>

            <Route exact path="/Authoring/itemtype/rearrange/bank/:bankid">
              <Header selectedtab="Authoring" appbgcolor={lighttheme}></Header>
              <ReArrangeEditor></ReArrangeEditor>
            </Route>
            <Route
              exact
              path="/Authoring/itemtype/rearrange/bank/:bankid/item/:itemid"
            >
              <Header selectedtab="Authoring" appbgcolor={lighttheme}></Header>
              <ReArrangeEditor></ReArrangeEditor>
            </Route>

            <Route exact path="/Authoring/itemtype/match/bank/:bankid">
              <Header selectedtab="Authoring" appbgcolor={lighttheme}></Header>
              <MatchEditor></MatchEditor>
            </Route>
            <Route
              exact
              path="/Authoring/itemtype/match/bank/:bankid/item/:itemid"
            >
              <Header selectedtab="Authoring" appbgcolor={lighttheme}></Header>
              <MatchEditor></MatchEditor>
            </Route>



            <Route path="/Authoring/content/id/:id">
              <Header selectedtab="Authoring" appbgcolor={lighttheme}></Header>
              <AuthorContent></AuthorContent>
            </Route>
            <Route path="/Authoring/content/cid/:cid">
              <Header selectedtab="Authoring" appbgcolor={lighttheme}></Header>
              <AuthorContent></AuthorContent>
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </MuiThemeProvider>
  );
}
