import React, { Fragment, useState, useEffect, useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useParams } from "react-router-dom";
import axios from "axios";
import AuthService from "../../utils/AuthService";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Moment from "react-moment";
import "moment-timezone";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListItemText from "@material-ui/core/ListItemText";
import { Markup } from "interweave";
import AtsBreadCrumbs from "../AtsBreadCrumbs";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CommentIcon from "@material-ui/icons/Comment";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RedoIcon from "@material-ui/icons/Redo";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import AutoSaveProgress from "./AutoSaveProgress";
import { CRC32 } from "../../utils/Utils";
import Authorized from "../Authorized";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ApiMap from "../../utils/ApiMap";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  arbhdrcell: {
    backgroundColor: "#03A9F4",
    fontSize: "xx-large",
    fontWeight: 400,
    padding: "1px",
    height: "50px",
    maxHeight: "50px",
    border: "0px solid #84b4cc",
    overflow: "hidden",
    fontFamily: "'Traditional Arabic',Lateef",
  },
  hdrcell: {
    backgroundColor: "#03A9F4",
    fontSize: "large",
    padding: "1px",
    height: "50px",
    overflow: "hidden",
    maxHeight: "50px",
    border: "0px solid #84b4cc",
  },
  readonlycell: {
    paddingLeft: "5px",
    paddingTop: "5px",
    border: "1px solid #799bac",

    textAlign: "left",
    verticalAlign: "middle",
    color: "#666666",
    fontSize: "large",
    overflow: "auto",
  },
  readonlyarbcell: {
    padding: "5px",
    border: "1px solid #E0E0E0",
    fontSize:"xx-large",
    direction: "rtl",
    fontFamily: "'Traditional Arabic',Lateef",
    textAlign: "right",
    color: "#666666",
    overflow: "auto",
  },
  arbdatacell: {
    paddingRight: "5px",
    paddingTop: "5px",
    border: "1px solid #E0E0E0",
    textAlign: "right",
    overflow: "auto",
  },
  datacell: {
    paddingLeft: "15px",
    paddingTop: "5px",
    border: "1px solid #E0E0E0",
    textAlign: "left",
    overflow: "auto",
  },
  arbtext: {
    fontSize: "xx-large",
    padding: "3px 0 3px",
    fontFamily: "'Traditional Arabic',Lateef",
    fontWeight: 500,
    color: "blue",
    lineHeight: 1,
  },
  redarbtext: {
    fontSize: "xx-large",
    padding: "3px 0 3px",
    fontFamily: "'Traditional Arabic',Lateef",
    fontWeight: 500,
    color: "red",
    lineHeight: 1,
  },
  engtext: {
    fontSize: 18,
    color: "blue",
  },
  redengtext: {
    fontSize: 18,
    color: "red",
  },
}));

const AssignmentTextField = withStyles({
  root: {
    "& .MuiInput-underline:before": {
      borderBottom: "1px dashed #888",
    },
    "& .MuiInput-underline:hover::before": {
      borderBottom: "1px dashed darkorange",
    },
    "& .MuiInput-underline::after": {
      borderBottom: "1px dashed #ff6e40",
    },
  },
})(TextField);

const gradeStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

function GradedMsg(props) {
  var per = (props.totalscore / props.studentscore) * 100;
  if (per >= 98) {
    return (
      <Typography variant="body1" style={{ color: "green" }} component="p">
        Well Done
        <span role="img" aria-label="Well Done">
          👏👏
        </span>
      </Typography>
    );
  }
  if (per >= 85) {
    return (
      <Typography variant="body1" style={{ color: "green" }} component="p">
        Good job
        <span role="img" aria-label="Good job">
          👏👏
        </span>
      </Typography>
    );
  }
  if (per >= 80) {
    return (
      <Typography variant="body1" style={{ color: "green" }} component="p">
        <span role="img" aria-label="Average">
          😕
        </span>
      </Typography>
    );
  }
  if (per >= 75) {
    return (
      <Typography variant="body1" style={{ color: "green" }} component="p">
        <span role="img" aria-label="Get serious">
          😨
        </span>
      </Typography>
    );
  }
  return (
    <Typography variant="body1" style={{ color: "green" }} component="p">
      <span role="img" aria-label="Risk of drop out">
        😭
      </span>
    </Typography>
  );
}
function MyAssignmentGrade(props) {
  //console.log(props);
  const classes = gradeStyles();
  const bull = <span className={classes.bullet}>•</span>;
  return (
    <div
      style={{
        textAlign: "left",
        paddingLeft: "25px",
        paddingRight: "25px",
        color: "#9E9E9E",
        marginBottom: "25px",
      }}
    >
      <Card className={classes.root}>
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Your Grades
          </Typography>
          <Typography variant="h5" component="h2">
            {props.myScore}
            {bull}out of {bull}
            {props.assignmentScore}
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            <Typography variant="caption" component="p">
              Submitted:{" "}
              <Moment format="MMMM Do YYYY, h:mm a" utc local>
                {props.myGradeSubmitDate}
              </Moment>
            </Typography>
            <Typography variant="caption" component="p">
              {props.myGradedDate ? (
                <Fragment>
                  Graded:{" "}
                  <Moment format="MMMM Do YYYY, h:mm a" utc local>
                    {props.myGradedDate}
                  </Moment>
                </Fragment>
              ) : (
                <Typography variant="overline">Not Graded Yet</Typography>
              )}
            </Typography>
          </Typography>
          <Typography variant="body1" component="p">
            <ListItem>
              <ListItemIcon>
                <CommentIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body1" component="p">
                    {props.myGradedTeacherComment}
                  </Typography>
                }
              />
            </ListItem>
          </Typography>
        </CardContent>
        <CardActions>
          <Typography color="primary">
            {props.myResubmit ? (
              <ListItem>
                <ListItemIcon>
                  <RedoIcon style={{ color: "red" }} />
                </ListItemIcon>
              </ListItem>
            ) : (
              <ListItem>
               
              </ListItem>
            )}
          </Typography>
        </CardActions>
      </Card>
    </div>
  );
}
function GradeAssignment({
  studentId,
  studentName,
  assignmentScore,
  studentCurScore,
  teacherComment,
  needReSubmit,
  userAssignmentId,
  courseId,
  moduleId,
  chapterId,
  contentId
}) {
  const [newStudentScore, setNewStudentScore] = useState(studentCurScore);
  const [gradeComment, setGradeComment] = useState(teacherComment);
  const [resubmit, setResubmit] = useState(needReSubmit);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    setNewStudentScore(studentCurScore);
    setGradeComment(teacherComment);
    setResubmit(needReSubmit);
  }, [studentId]);

  function setResubmitFlag() {
    setResubmit(!resubmit);
  }

  function submitGrades({}) {
    const assignmentObj = {
      comment: gradeComment,
      score: newStudentScore,
      resubmit: resubmit,
      Id: userAssignmentId,
      studentId: studentId,
      assignmentGrades: assignmentScore
    };
    setUpdating(true);
    var url = `${ApiMap.UPDT_ASSIGNMENT}/gradeassignment/${courseId}/module/${moduleId}/chapter/${chapterId}/lesson/${contentId}`;
    async function update() {
      try {
        const response = await axios.post(
          url,
          assignmentObj,
          AuthService.getAuthHeader()
        );
        setUpdating(false);
        if (response.status === 200) {
        }
      } catch (err) {
        console.log(err);
        setUpdating(false);
      }
    }
    update();
  }

  function updateScore(e) {
    setNewStudentScore(e.target.value);
  }

  return (
    <div
      style={{
        textAlign: "left",
        paddingLeft: "25px",
      }}
    >
      <Typography
        style={{
          color: "#ff0000",
          fontStyle: "italic",
          fontSize: "16px",
        }}
        display="inline"
        color="primary"
      >
        By {studentName}
      </Typography>
      <div
        style={{
          marginBottom: "25px",
          marginTop: "15px",
        }}
      >
        <div
          style={{
            marginBottom: "15px",
          }}
        >
          Grade out of {assignmentScore}
        </div>

        <div
          style={{
            width: "200px",
          }}
        >
          <TextField
            variant="outlined"
            key={"score_" + studentId}
            required
            type="number"
            fullWidth
            label="Student Grade"
            name="studentgrade"
            autoComplete="off"
            inputProps={{
              max: assignmentScore,
              min: 0,
            }}
            InputProps={{
              inputProps: {
                max: assignmentScore,
                min: 0,
              },
            }}
            style={{
              display: "block",
            }}
            onChange={(e) => updateScore(e)}
            defaultValue={studentCurScore}
          />
        </div>
        <div
          style={{
            paddingRight: "25px",
          }}
        >
          <TextField
            variant="outlined"
            required
            key={"comment_" + studentId}
            rows={4}
            fullWidth
            label="Enter comments"
            style={{
              marginTop: "15px",
              marginBottom: "15px",
            }}
            multiline
            onChange={(e) => setGradeComment(e.target.value)}
            defaultValue={teacherComment || ""}
          />
          <div key={"resubmit_" + studentId}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={setResubmitFlag}
                  color="primary"
                  checked={resubmit}
                />
              }
              label="Resubmit"
            />
          </div>

          <Button onClick={submitGrades} variant="contained" color="secondary">
            Grade
          </Button>
          {updating ? (
            <span style={{ margin: "9px" }}>
              <FontAwesomeIcon icon={faSpinner} size="lg" spin />
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
}

let teacherCommentColHeaderNames = ["teacher's comment", "teacher's comments", "teacher comment","teacher comments","تعليق المدرس"];

export default function Assignment({ quizid, addCallback }) {
  // console.log("Assignment");
  const classes = useStyles();
  const [assignment, setAssignment] = useState(null);
  const [userAssignment, setUserAssignment] = useState(null);
  const [assignmentId, setAssignmentId] = useState(null);
  //const [userAssignmentId, setUserAssignmentId] = useState(null);
  const userAssignmentId = useRef();
  const [myAssignment, setMyAssignment] = useState();
  const [submissions, setSubmissions] = useState(null);
  const [assignmentInfo, setAssignmentInfo] = useState({});
  const assignmentCreated = useRef(false);
  const [selStudentSubmission, setSelStudentSubmission] = useState();
  let { contentId, courseId, chapterId, moduleId } = useParams();
  const getAssignment = async () => {
    if (assignmentId > 0) {
      return;
    }
    const resp = await axios(
      `${ApiMap.MYASSIGNMENT}/myassignment/${courseId}/module/${moduleId}/chapter/${chapterId}/lesson/${contentId}`,
      AuthService.getAuthHeader()
    );
    if (resp.data.UserAssignmentId) {
      //setUserAssignmentId(resp.data.UserAssignmentId);
      userAssignmentId.current = resp.data.UserAssignmentId;
      setUserAssignment(resp.data.Assignment);
      var myA = {};
      myA.GradeSubmitDate = resp.data.SubmitDate;
      myA.UserAssignmentId = resp.data.UserAssignmentId;
      myA.Assignment = resp.data.Assignment;
      myA.Score = resp.data.StudentScore;
      myA.MemberId = resp.data.MemberId;
      myA.GradedDate = resp.data.GradedDate;
      myA.TeacherComment = resp.data.TeacherComment;
      myA.StudentComment = resp.data.Studentcomment;
      myA.Resubmit = resp.data.ReSubmit;
      setMyAssignment(myA);
    } else {
      setUserAssignment(resp.data.Content);
    }

    if (!assignment) {
      setAssignment(resp.data.Content);
    }

    var ainfo = {};
    ainfo.Title = resp.data.Title;
    ainfo.Name = resp.data.Name;
    ainfo.CourseName = resp.data.CourseName;
    ainfo.ModuleName = resp.data.ModuleName;
    ainfo.Duration = resp.data.DurationMin;
    ainfo.Score = resp.data.AssignmentScore;
    setAssignmentInfo(ainfo);
    setAssignmentId(resp.data.AssignmentId);
  };

  const getSubmissions = async () => {
    const resp = await axios(
      `${ApiMap.MYASSIGNMENT}/assignmentstograde/${courseId}/module/${moduleId}/chapter/${chapterId}/lesson/${contentId}`,
      AuthService.getAuthHeader()
    );
    setSubmissions(resp.data);
  };

  useEffect(() => {
    if (assignmentId < 1) {
      getAssignment();
    }
  }, [assignmentId]);

  useEffect(() => {
    if (AuthService.isFaculty(courseId) && assignmentId != null && !submissions) {
      getSubmissions();
    }
  });


  const getColStyle = (width) => {
    var style = {
      textAlign: "center",
      border: "0px solid #799bac",
      borderBottom: "0px",
      flex: 1,
    };
    var w = (10 / assignment.columns.length) * 10;
    if (width) {
      w = `${width}%`;
    } else {
      w = `${w}%`;
    }
    style["max-width"] = w;
    return style;
  };

  const getColHeight = (data,stylesToAdd) => {
    var defLines = 1;
    if (assignment.lines && assignment.lines > 0) {
      defLines = assignment.lines;
    }
    let defHt = 70;
    if (data && data.startsWith("{Q}_")) {
      defHt = 70;
    }
    var style = {
      height: defHt * defLines + "px",
    };
    if (stylesToAdd && stylesToAdd.length > 0) {
      stylesToAdd.map(function (s, i) {
        style[s.name] = s.value;
      });
    }
    return style;
  };

  useEffect(() => {
    if (!assignment) {
      return;
    }
    var maxRows = 0;
    assignment.data.map(function (col, i) {
      if (col.length > maxRows) {
        maxRows = col.length;
      }
    });

    assignment.data = assignment.data.map(function (col, i) {
      for (var n = col.length; n < maxRows; n++) {
        col[n] = "";
      }
      return col;
    });
  }, [assignment]);

  const createUserAssignment = () => {
    if (assignmentCreated.current) {
      return;
    }
    assignmentCreated.current = true;
    var url = `${ApiMap.CREATEASSIGNMENT}/userassignment/${courseId}/module/${moduleId}/chapter/${chapterId}/lesson/${contentId}`;
    async function create() {
      try {
        const response = await axios.put(
          url,
          userAssignment,
          AuthService.getAuthHeader()
        );
        if (response.status === 200) {
          userAssignmentId.current = response.data.id;
        }
      } catch (err) {
        console.log(err);
      }
    }
    create();
  };

  function getUserEnteredData(row, col, data) {
    if (userAssignment) {
      return userAssignment.data[col][row];
    }
    return null;
  }

  function UserAssignments({ selstudent }) {
    const [expand, setExpand] = useState(true);
    const [loadingStuAssignment, setLoadStuAssignment] = useState(false);
    const onClickPanel = () => {
      setExpand(!expand);
    };

    function loadStudentSubmission(student) {
      setLoadStuAssignment(true);
      setSelStudentSubmission(student);
      userAssignmentId.current = student.UserAssignmentId;
      setUserAssignment(student.Assignment);
      setLoadStuAssignment(false);
    }

    if (!AuthService.isFaculty(courseId)) {
      return null;
    }
    if (submissions === null || submissions.length < 1) {
      return null;
    }
    var defstu;
    var students = submissions.map(function (member, i) {
      const firstLetter = member.FirstName[0].toUpperCase();
      var ret = {
        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
        name: member.FirstName + " " + member.LastName,
        MemberId: member.MemberId,
        UserAssignmentId: member.UserAssignmentId,
      };
      if (selstudent && member.MemberId === selstudent.MemberId) {
        defstu = ret;
      }
      return ret;
    });

    return (
      <div
        className={classes.root}
        style={{
          textAlign: "left",
          padding: "25px",
          color: "#9E9E9E",
          fontSize: "16px",
        }}
      >
        <ExpansionPanel expanded={expand}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={onClickPanel}
          >
            <Typography color="primary" className={classes.heading}>
              Submissions: {submissions.length}
            </Typography>
          </ExpansionPanelSummary>
          <Box display={{ xs: "block", sm: "none" }}>
            <ExpansionPanelDetails>
              <Fragment>
                <Autocomplete
                  defaultValue={defstu}
                  id="combo-box-demo"
                  options={students.sort(
                    (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                  )}
                  getOptionLabel={(option) => option.name}
                  style={{ width: 300, display: "block" }}
                  onChange={(event, newValue) => {
                    loadStudentSubmission(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Student"
                      variant="outlined"
                    />
                  )}
                />
                <div
                  style={{
                    verticalAlign: "middle",
                    top: "10px",
                    position: "relative",
                    padding: "10px",
                  }}
                >
                  {loadingStuAssignment ? (
                    <FontAwesomeIcon icon={faSpinner} size="lg" spin />
                  ) : null}
                </div>
              </Fragment>
            </ExpansionPanelDetails>
          </Box>
          <Box display={{ xs: "none", sm: "block" }}>
            <ExpansionPanelDetails>
              {submissions.length > 12 ? (
                <Fragment>
                  <Autocomplete
                    defaultValue={defstu}
                    id="combo-box-demo"
                    options={students.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    getOptionLabel={(option) => option.name}
                    style={{ width: 300, display: "block" }}
                    onChange={(event, newValue) => {
                      loadStudentSubmission(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Student"
                        variant="outlined"
                      />
                    )}
                  />
                  <div
                    style={{
                      verticalAlign: "middle",
                      top: "10px",
                      position: "relative",
                      padding: "10px",
                    }}
                  >
                    {loadingStuAssignment ? (
                      <FontAwesomeIcon icon={faSpinner} size="lg" spin />
                    ) : null}
                  </div>
                </Fragment>
              ) : (
                <Grid container arxia-label="user assignment submissions">
                  {submissions.map(function (v, i) {
                    return (
                      <Grid item xs={6} sm={4} md={3} lg={2} key={"subm_" + i}>
                        <ListItem
                          onClick={() => loadStudentSubmission(v)}
                          button
                        >
                          <ListItemText
                            primary={v.FirstName}
                            secondary={
                              <Moment format="MMMM Do YYYY, h:mm a" utc local>
                                {v.SubmitDate}
                              </Moment>
                            }
                          />
                        </ListItem>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </ExpansionPanelDetails>
          </Box>
        </ExpansionPanel>
      </div>
    );
  }

  function AssignmentCol({ colno, coldata, inscolumn, direction }) {
    // console.log("AssignmentCol", colno, " coldata:", coldata, " inscol:", inscolumn);
    return coldata.map(function (data, i) {
      var key = colno + "_" + i;
      if (!data) {
        var userEnteredData = getUserEnteredData(i, colno, data);
        return (
          <div
            key={key}
            style={getColHeight()}
            className={
              direction === "ltr" ? classes.datacell : classes.arbdatacell
            }
          >
            <AssignmentCell
              key={"as_" + key}
              data={userEnteredData}
              colno={colno}
              inscolumn={inscolumn}
              rowno={i}
              direction={direction}
            ></AssignmentCell>
          </div>
        );
      } else {
        return (
          <div
            key={key}
            style={getColHeight(data)}
            className={
              direction === "ltr"
                ? classes.readonlycell
                : classes.readonlyarbcell
            }
          >
            {colno === 0 ? parseInt(i) + 1 : null}
            <AssignmentData data={data}></AssignmentData>
          </div>
        );
      }
    });
  }

  function AssignmentData({data}) {
    
    if (data.startsWith("{Q}_")) {
      let qinfo = data.split("_");
      let ayahmp3 = `https://mp3.askquran.com/audio/ayah/${qinfo[1]}/${qinfo[2]}.mp3`;
      return  <div>          
          <audio controls>
              <source 
              src={ayahmp3}
              type="audio/mpeg"/> 
            </audio>
        </div>
    } 
    return <Fragment> {data}</Fragment>  
  }

  function AssignmentCell({ data, rowno, inscolumn, colno, direction }) {
    console.log(data, rowno, inscolumn, colno, direction);
    function updateVal(e, row, col) {
      let newArr = userAssignment;
      newArr.data = [...userAssignment.data];
      newArr.data[col][row] = e.target.value;
      setUserAssignment(newArr);
    }
    var key = "t_" + rowno + "_" + colno;
    if (!direction || direction === "rtl") {
      var textStyle = classes.arbtext;
      if (inscolumn) {
        textStyle = classes.redarbtext;
      }
      if (assignment.lines && assignment.lines > 1) {
        return (
          <AssignmentTextField
            style={{
              direction: "rtl",
              width: "95%",
            }}
            key={key}
            multiline
            InputProps={{
              classes: {
                input: textStyle,
              },
            }}
            disabled={inscolumn && AuthService.isStudent(courseId) ? true : false}
            onChange={(e) => updateVal(e, rowno, colno)}
            defaultValue={data}
          />
        );
      } else {
        return (
          <AssignmentTextField
            key={key}
            style={{
              direction: "rtl",
              width: "95%",
            }}
            InputProps={{
              classes: {
                input: textStyle,
              },
            }}
            disabled={inscolumn && AuthService.isStudent(courseId) ? true : false}
            onChange={(e) => updateVal(e, rowno, colno)}
            defaultValue={data}
          />
        );
      }
    }
    var textStyle = classes.engtext;
    if (inscolumn) {
      textStyle = classes.redengtext;
    }
    if (assignment.lines && assignment.lines > 1) {
      return (
        <AssignmentTextField
          key={key}
          style={{ direction: "ltr", width: "95%" }}
          multiline
          InputProps={{
            classes: {
              input: textStyle,
            },
          }}
          disabled={inscolumn && AuthService.isStudent(courseId) ? true : false}
          onChange={(e) => updateVal(e, rowno, colno)}
          defaultValue={data}
        />
      );
    } else {
      return (
        <AssignmentTextField
          key={key}
          style={{ direction: "ltr", width: "95%" }}
          InputProps={{
            classes: {
              input: textStyle,
            },
          }}
          disabled={inscolumn && AuthService.isStudent(courseId) ? true : false}
          onChange={(e) => updateVal(e, rowno, colno)}
          defaultValue={data}
        />
      );
    }
  }

  if (!AuthService.isLoggedIn()) {
    return (
      <Authorized
        refRoute={`Assignment/course/${courseId}/module/${moduleId}/chapter/${chapterId}/lesson/${contentId}`}
      />
    );
  }

  if (!assignment) {
    return (
      <div style={{ margin: "0 auto", width: "200px", textAlign: "center" }}>
        <Typography>Loading assignment...</Typography>
        <LinearProgress color="primary" />
      </div>
    );
  }

  if (AuthService.isFaculty(courseId) && !submissions) {
    return (
      <div style={{ margin: "0 auto", width: "200px", textAlign: "center" }}>
        <Typography>Loading submissions...</Typography>
        <LinearProgress color="secondary" />
      </div>
    );
  }

  function getUserAssignment() {
    const assignmentObj = {
      Value: userAssignment
    };
    if (userAssignmentId.current) {
      assignmentObj["Id"] = parseInt(userAssignmentId.current);
    }
    if (selStudentSubmission) {
      assignmentObj["StudentId"] = selStudentSubmission.MemberId;
    } 
    return assignmentObj;
  }

  const myCourseLink = "/Courses";
  const courseBrdcmbLnk = "/Modules/Course/" + courseId;
  const moduleBrdcmbLnk = `/Lessons/${courseId}/Module/${moduleId}`;

  const breadcrumbs = [
    { title: "My Courses", link: myCourseLink },
    { title: `${assignmentInfo.CourseName}`, link: courseBrdcmbLnk },
    { title: `${assignmentInfo.ModuleName}`, link: moduleBrdcmbLnk },
  ];

  return (
    <List className={classes.root}>
      <AtsBreadCrumbs
        last={assignmentInfo.Name}
        crumbs={breadcrumbs}
      ></AtsBreadCrumbs>
      <UserAssignments selstudent={selStudentSubmission}></UserAssignments>
      <Grid container spacing={0.1}>
        <Grid alignItems="left" justify="space-evenly" item xs={12}>
          {selStudentSubmission ? (
            <GradeAssignment
              studentId={selStudentSubmission.MemberId}
              studentName={selStudentSubmission.FirstName}
              studentCurScore={selStudentSubmission.StudentScore}
              assignmentScore={assignmentInfo.Score}
              userAssignmentId={userAssignmentId.current}
              needReSubmit={selStudentSubmission.ReSubmit}
              teacherComment={selStudentSubmission.TeacherComment}
              studentComment={selStudentSubmission.StudentComment}
              courseId={courseId}
              moduleId={moduleId}
              chapterId={chapterId}
              contentId={contentId}
            ></GradeAssignment>
          ) : null}
          {myAssignment && !AuthService.isFaculty(courseId)? (
            <MyAssignmentGrade
              myGradeSubmitDate={myAssignment.GradeSubmitDate}
              myScore={myAssignment.Score}
              assignmentScore={assignmentInfo.Score}
              myGradedDate={myAssignment.GradedDate}
              myResubmit={myAssignment.Resubmit}
              myGradedTeacherComment={myAssignment.TeacherComment}
              myGradedStudentComment={myAssignment.StudentComment}
            ></MyAssignmentGrade>
          ) : null}
        </Grid>
      </Grid>
      <Grid container spacing={0.1}>
        <Grid
          style={{
            textAlign: "left",
            paddingLeft: "25px",
            fontSize: "large",
            fontWeight: 500,
          }}
          item
          xs={12}
        >
          <Markup content={assignmentInfo.Title} />
        </Grid>
      </Grid>
      <Grid style={{ textAlign: " center" }} justify="space-evenly" xs={12}>
        Duration : {assignmentInfo.Duration} Minutes
      </Grid>
      <AutoSaveProgress
        key={assignmentId}
        aid={assignmentId}
        contentId={contentId}
        courseId={courseId}
        chapterId={chapterId}
        moduleId={moduleId}
        crc={CRC32(JSON.stringify(getUserAssignment()))}
        getUserAssignmentCallback={getUserAssignment}
        createUserAssignmentCallback={createUserAssignment}
      ></AutoSaveProgress>

      <ListItem alignItems="flex-start">
        <Grid
          container
          justify="space-evenly"
          alignItems="stretch"
          direction="row-reverse"
          style={{ width: "99%", display: "flex", border: "0px solid #799bac" }}
          spacing={0.1}
        >
          {assignment.columns.map(function (a, col) {
            return (
              <Grid key={col} style={getColStyle(a.width)} item>
                <div
                  className={
                    a.direction === "ltr" ? classes.hdrcell : classes.arbhdrcell
                  }
                >
                  {a.name}
                </div>
                <AssignmentCol
                  colno={col}
                  inscolumn={
                    teacherCommentColHeaderNames.indexOf(a.name.toLowerCase()) > -1
                      ? true
                      : false
                  }
                  direction={a.direction}
                  coldata={assignment.data[col]}
                ></AssignmentCol>
              </Grid>
            );
          })}
        </Grid>
      </ListItem>
    </List>
  );
}