import React from "react";
import Link from "@material-ui/core/Link";

export default function MuiLink({ text, link, icon, color }) {
  if (icon) {
    return (
      <Link color={color} href={link}>
        {icon}
      </Link>
    );
  }
  return (
    <Link color={color} href={link}>
      {text}
    </Link>
  );
}
