class ApiMap {
    API_BASE_URL = process.env.REACT_APP_API_GATEWAY_URL;
    REALTIME_API_URL = process.env.REACT_APP_WSAPI_GATEWAY_URL;
    LMS_REST_ENDPOINT = `${this.API_BASE_URL}app/v1`;
    COURSE_LEADERBOARD = `${this.API_BASE_URL}app/v1`;
    COURSE_MODULES = `${this.API_BASE_URL}app/v1`;
    MODULES_LESSONS = `${this.API_BASE_URL}app/v1`;
    CONTENT = `${this.API_BASE_URL}app/v1`;
    MARK_LSN_COMPLETE = `${this.API_BASE_URL}app/v1/markcomplete`;
    MARK_LSN_INCOMPLETE = `${this.API_BASE_URL}app/v1/markincomplete`;
    GAME_ASSESSMENT  = `${this.API_BASE_URL}app/v1/game`;
    GAME_INFO  = `${this.API_BASE_URL}app/v1/gameinfo`;
    MYASSIGNMENT = `${this.API_BASE_URL}app/v1`;
    CREATEASSIGNMENT = `${this.API_BASE_URL}app/v1`;
    UPDT_ASSIGNMENT = `${this.API_BASE_URL}app/v1`;
    GRADE_ASSIGNMENT = `${this.API_BASE_URL}app/v1`;
    ENROL_USER = `${this.API_BASE_URL}app/v1`;
    ASSESSMENT = `${this.API_BASE_URL}app/v1`;
    ITEM_ATTEMPT = `${this.API_BASE_URL}app/v1`;
    GET_GAMECODE = `${this.API_BASE_URL}app/v1`;
    MY_ASSIGNMENTDUECOUNT = `${this.API_BASE_URL}app/v1/myassignmentsduecount`;
    MY_ASSIGNMENTDUE = `${this.API_BASE_URL}app/v1/myassignmentsdue`;
    CRS_ASSIGNMENTS_GRADING_DUE = `${this.API_BASE_URL}app/v1/ungradedassignments`;
    CREATELESSON  = `${this.API_BASE_URL}app/v1/courselesson`;
    UPDTLESSON = `${this.API_BASE_URL}app/v1/courselesson`;
    GETTAXANOMY  = `${this.API_BASE_URL}app/v1/taxanomy`;
    ASSIGNMENT_CONTENT = `${this.API_BASE_URL}app/v1/assignmentcontent`;    
}

export default new ApiMap();
