import React, { useState, useEffect, Fragment } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import axios from "axios";
import AuthService from "../../utils/AuthService";
import { Editor } from "@tinymce/tinymce-react";
import { Grid } from "@material-ui/core";
import AtsBreadCrumbs from "../AtsBreadCrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useParams } from "react-router-dom";
import { ArabicGrammarTerms } from "../../utils/ArabicGrammarTerms";
import { MarkupWordTypes } from "./MarkupWordTypes";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import FormatTextdirectionLToRIcon from '@material-ui/icons/FormatTextdirectionLToR';
import FormatTextdirectionRToLIcon from '@material-ui/icons/FormatTextdirectionRToL';
import Tooltip from '@material-ui/core/Tooltip';
import uuid from 'uuid/v4';
import ClearAllIcon from '@material-ui/icons/ClearAll';
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "transparent",
  },
  grow: { flexGrow: 1 },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    textAlign: "center",
  },
  form: {
    padding: "7px",
    margin: "7px",
  },
  wordtype: {
    fontSize: "x-large",

    borderBottom: "1px dashed #E1E1E1",
    width: "100%",

    color: "#0000ff",
  },
  stemtext: {
    fontSize: "x-large",
    backgroundColor: "#EFEFEF",
    border: "1.5px dashed #555",
    padding: "15px",
    lineHeight: "2.5rem",
  },
}));

const defaultVals = {
  Rationale: {
    ContentId: "572666710",
  },
  Text:
    "وَالْأَرْضَ مَدَدْنَاهَا وَأَلْقَيْنَا فِيهَا رَوَاسِيَ وَأَنبَتْنَا فِيهَا مِن كُلِّ زَوْجٍ بَهِيجٍ",
  Direction: "RTL",
  MarkupType: "Underline",
  Options: {},
};

const AssignmentTextField = withStyles({

  root: {
    "& .MuiInput-underline:before": {
      borderBottom: "1px dashed #888",
    },
    "& .MuiInput-underline:hover::before": {
      borderBottom: `1px dashed darkorange`,

    },
    "& .MuiInput-underline::after": {
      borderBottom: `0px solid #ff6e40`,
    },
  },

})(TextField);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function WordTypeTextField({ value,
  wkey,
  col,
  openDlgCallback,
  helpertext,
  disable,
  dltOptionCallback,
  value2,
  direction
}) {
  const classes = useStyles();
  const [openMsgValue2, setOpenMsgVaule2] = React.useState(false);

  const handleClickMsgOpenValue2 = () => {
    setOpenMsgVaule2(true);
  };

  const handleCloseMsgValue2 = () => {
    setOpenMsgVaule2(false);
  };

  function showDlg() {
    openDlgCallback(wkey, col);
  }

  function handleDltOpt() {
    dltOptionCallback(value2, wkey, col)
  }

  return (<Fragment>
    <AssignmentTextField
      InputProps={{
        classes: {
          input: classes.wordtype,
        },
        startAdornment: (
          <InputAdornment
            position="start"
          >
            <AddCircleIcon onClick={disable ? handleClickMsgOpenValue2 : showDlg}
              style={{ cursor: "pointer", paddingLeft: "4px" }}
            />
          </InputAdornment>
        ),
        endAdornment:
          (
            <InputAdornment position="start">
              {
                value ? <HighlightOffIcon
                  onClick={handleDltOpt}
                  style={{ cursor: "pointer", paddingLeft: "4px" }}
                /> : null
              }
            </InputAdornment>
          ),
      }}

      disabled={disable}
      placeholder={helpertext}
      style={{
        width: "100%",
        direction: direction ? "ltr" : "rtl",
        textAlign: direction ? "ltr" : "rtl"
      }}
      defaultValue={value}
    >
    </AssignmentTextField >

    <Dialog
      open={openMsgValue2}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseMsgValue2}
    >
      <DialogContent >
        <DialogContentText>
          <Typography variant="h6" >
            Please enter the value1
            </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseMsgValue2} color="secondary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  </Fragment >
  );
}

function MarkupContent({
  options,
  text,
  updateContentTextCallback,
  updateWordTypeCallback,
  deleteOptionCallback,
  direction
}) {
  const classes = useStyles();
  const [openWordTypeMenu, setOpenWordTypeMenu] = React.useState(false);
  const [wkey, setWkey] = React.useState();
  const [wcol, setWcol] = React.useState();
  var newStr = text.replace(/  +/g, " ");
  var words = newStr.split(" ").map(function (item, index) {
    return item.trim();
  });

  function updateContentText(e) {
    updateContentTextCallback(e.target.value.trim());
  }

  const handleOpenWordTypeMenu = (row, col) => {
    setWcol(col);
    setWkey(row);
    setOpenWordTypeMenu(true);
  };

  const handleOptionDelete = (value2, row, col) => {
    deleteOptionCallback(value2, row, col);
  };

  const handleWordTypeSelection = (val) => {
    updateWordTypeCallback(val, wkey, wcol);
    setOpenWordTypeMenu(false);
  };

  const handleClose = () => {
    setOpenWordTypeMenu(false);
  };
  return (
    <div>
      <Grid container spacing={1} >
        <Grid item xs={12}>
          <TextField
            InputProps={{
              classes: {
                input: classes.stemtext,
              },
            }}
            defaultValue={text}
            multiline
            rows={4}
            style={{ width: "100%", direction: direction ? "ltr" : "rtl" }}
            onChange={(e) => updateContentText(e)}
          ></TextField>
        </Grid>
      </Grid>
      <MarkupWordTypes
        open={openWordTypeMenu}
        handleCloseCallback={handleClose}
        handleWordTypeSelectionCallback={handleWordTypeSelection}
        dict={ArabicGrammarTerms}
        word={words}
        wkey={wkey}
      ></MarkupWordTypes>
      {words.map(function (w, i) {
        var wkey = ++i;
        var type1 = "";
        var key = uuid();
        if (options[wkey]) {
          type1 = options[wkey][0].Type;
          console.log(options[wkey][0].Type);
        }
        var type2 = "";
        if (options[wkey] && options[wkey].length > 1) {
          type2 = options[wkey][1].Type;
          console.log(options[wkey][0].Type)
        }
        console.log(wkey, type1, type2);
        return (
          <div key={key} style={{
            borderLeft: "1px solid #888",
            borderRight: "1px solid #888"
          }}>
            <Grid container spacing={1} >
              <Grid item xs={4}  >
                <div
                  style={{
                    fontSize: "x-large",
                    lineHeight: "2.6rem",
                    direction: direction ? "ltr" : "rtl",
                    borderBottom: "1px dashed #888",
                    width: "100%",
                    textAlign: direction ? "left" : "right",
                  }}
                >
                  {i}.{w}
                </div>
              </Grid>
              <Grid item xs={4} style={{
                borderLeft: "1px dashed #888",
                borderRight: "1px dashed #888"
              }}>
                <WordTypeTextField
                  wkey={wkey}
                  value={type1}
                  openDlgCallback={handleOpenWordTypeMenu}
                  dltOptionCallback={handleOptionDelete}
                  words={words}
                  helpertext={"value1"}
                  value2={type2}
                  col={0}
                  direction={direction}
                ></WordTypeTextField>
              </Grid>
              <Grid item xs={4}>
                <WordTypeTextField
                  wkey={wkey}
                  value={type2}
                  openDlgCallback={handleOpenWordTypeMenu}
                  dltOptionCallback={handleOptionDelete}
                  helpertext={"value2"}
                  disable={type1 ? false : true}
                  direction={direction}
                  col={1}
                ></WordTypeTextField>
              </Grid>
            </Grid>
          </div>
        );
      })}
    </div>
  );
}

export default function MarkupItemEditor() {
  let { itemid, bankid } = useParams();
  const classes = useStyles();

  const [content, setContent] = React.useState();
  const [stemText, setStemText] = React.useState();
  const [options, setOptions] = React.useState();
  const [name, setName] = React.useState("");
  const [cat, setCat] = React.useState("itemcat#genders;skilltype#identify");
  const [title, setTitle] = React.useState("");
  const [itemType, setItemType] = useState("Markup");
  const [dbId, setDbId] = React.useState(itemid);
  const [direction, setDirection] = React.useState(false)


  function setDefaultValues() {
    setContent(defaultVals);
    setStemText(defaultVals.Text);
    setOptions(defaultVals.Options);
  }

  useEffect(() => {
    var url = `${AuthService.API_BASE_URL}api/item/contentbyid/id/${dbId}`;
    const fetchData = async () => {
      const resp = await axios(url, AuthService.getAuthHeader());
      console.log(resp);
      var item = JSON.parse(resp.data.Value);
      setStemText(item.Text);
      setOptions(item.Options);
      setItemType(resp.data.ItemType);
      setTitle(resp.data.Title);
      setName(resp.data.Name);
      setContent(item);
    };
    if (dbId) {
      fetchData();
    } else {
      setDefaultValues();
    }
  }, [dbId]);

  const createContent = (e) => {
    e.preventDefault();
    var newContent = content;
    newContent.Text = stemText;
    newContent.Options = options;
    console.log(content, stemText, options)
    const key = new Date().getTime();
    const contentObj = {
      Name: name,
      Title: title,
      ...content,
      "Type": itemType,
      "GPK1": "item",
      "GSK1": `item#${key}`,
      "PK": cat,
      "SK": `item#${key}`,
      "createdBy": "3b4f5146-b43f-4508-b136-3490cfa7dd11",
      "dateCreated": new Date().toISOString(),
      "dateUpdated": new Date().toISOString(),
      "points": 1,
      "updatedBy": "3b4f5146-b43f-4508-b136-3490cfa7dd11",
      "version": "v1"      
    };
    console.log("Item:")
    console.log(JSON.stringify(contentObj));
    var url = `${AuthService.API_BASE_URL}api/item/pkgcontent`;
    async function createItem() {
      try {
        const response = await axios.put(
          url,
          contentObj,
          AuthService.getAuthHeader()
        );
        if (response.status === 200) {
          setDbId(response.data.id);
          addItemToBank(response.data.id, bankid);
        }
      } catch (err) {
        console.log(err);
      }
    }
    async function addItemToBank(itemId, bankId) {
      url = `${AuthService.API_BASE_URL}api/item/additemtobank`;
      try {
        await axios.put(
          url,
          { bankid: bankId, itemid: itemId },
          AuthService.getAuthHeader()
        );
      } catch (err) {
        console.log(err);
      }
    }
    createItem();
  };

  const updateContent = (e) => {
    e.preventDefault();
    var newContent = content;
    newContent.Text = stemText;
    newContent.Options = options;
    const contentObj = {
      Name: name,
      Title: title,
      Value: newContent,
      ItemType: itemType,
      ContentType: "Item",
      Duration: 0,
      DisplayRank: 0,
      MediaUrl: null,
      ContentFormat: "item",
      IsPackage: 0,
    };

    contentObj.Id = dbId;
    var url = `${AuthService.API_BASE_URL}api/item/updatecontent`;
    async function updateItem() {
      try {
        await axios.post(url, contentObj, AuthService.getAuthHeader());
      } catch (err) {
        console.log(err);
      }
    }
    updateItem();
  };

  function handleRightIcon() {
    setDirection(true)
  }

  function handleLeftIcon() {
    setDirection(false)
  }

  function updateContentText(newtext) {
    if (newtext === stemText) {
      return;
    }
    if (!newtext) {
      setStemText("-");
      setOptions({});

    } else {
      setStemText(newtext);
    }
  }

  function handleDeleteAllOption() {
    setOptions({});
  }

  function deleteOption(value2, wordnum, col) {
    let newopts = JSON.stringify(options);
    let newopt = JSON.parse(newopts);
    if (value2) {
      newopt[wordnum][0] = { Type: value2 };
      newopt[wordnum][1] = [];
    } else {
      delete newopt[wordnum];
    }
    setOptions(newopt);
  }

  function updateWordType(wtype, wordnum, col) {
    if (!options[wordnum]) {
      options[wordnum] = [];
    }
    options[wordnum][col] = { Type: wtype };
  }

  if (!content) {
    return <div>Loading</div>;
  }
  const breadcrumbs = [
    { title: "Item Banks", link: "/Authoring/itembanks" },
    { title: "Bank", link: `/Authoring/itembank/${bankid}` },
  ];
  var lastBreadcrumb = name;
  if (dbId) {
    lastBreadcrumb = name + "(" + dbId + ")";
  }

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={classes.appBar}
        style={{ boxShadow: "none" }}
      >
        <Toolbar>
          <AtsBreadCrumbs
            last={lastBreadcrumb}
            crumbs={breadcrumbs}
          ></AtsBreadCrumbs>
          <div className={classes.grow}></div>
          <Button
            autoFocus
            variant="contained"
            color="primary"
            style={{ marginLeft: "15px", marginRight: "15px" }}
            onClick={createContent}
          >
            {"Create"}
          </Button>
          {dbId ? (
            <Button
              autoFocus
              variant="contained"
              color="primary"
              onClick={updateContent}
            >
              {"Update"}
            </Button>
          ) : null}
        </Toolbar>
      </AppBar>
      <form className={classes.form} noValidate>
        {/* {dbId ? (
          <FormLabel>{bankname + " / " + dbId + " - " + itemType}</FormLabel>
        ) : (
          <FormLabel>{bankname + " / " + itemType}</FormLabel>
        )} */}
        <TextField
          variant="outlined"
          margin="normal"
          required
          id="name"
          label="Name"
          name="Name"
          value={name}
          fullWidth
          style={{
            maxWidth: "400px",
            display: "block",

          }}
          onChange={(e) => setName(e.target.value)}
          autoFocus
        />
        {/* {dbId ? (
          <FormLabel>{bankname + " / " + dbId + " - " + itemType}</FormLabel>
        ) : (
          <FormLabel>{bankname + " / " + itemType}</FormLabel>
        )} */}
        <TextField
          variant="outlined"
          margin="normal"
          required
          id="cat"
          label="Cat & Skill"
          name="cat"
          value={cat}
          fullWidth
          style={{
            maxWidth: "400px",
            display: "block",

          }}
          onChange={(e) => setCat(e.target.value)}
          autoFocus
        />
        <Typography style={{ color: "black" }}>Stem</Typography>
        <Editor
          apiKey="plgjmz2q4q6gougxbjnuycs8nmkg42fni1sne8bgj57ianzu"
          value={title}
          init={{
            plugins:
              "print preview paste importcss searchreplace autolink  directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons",
            menubar: "edit view insert format tools table help",
            toolbar:
              "undo redo | bold italic underline strikethrough | ltr rtl| formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview | insertfile image media template link anchor codesample ",
            toolbar_sticky: false,
            quickbars_insert_toolbar: false,
            quickbars_image_toolbar: false,
            quickbars_selection_toolbar:
              "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
            block_formats:
              "Div=div; Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3;  Header 4=h4;  Header 5=h5;  Header 6=h6; Preformatted=pre",
            autosave_ask_before_unload: true,
            image_advtab: true,
            content_css: "//www.tiny.cloud/css/codepen.min.css",
            importcss_append: true,
            height: 250,
            forced_root_block: "div",
            templates: [
              {
                title: "Audio",
                description: "Audio content",
                content:
                  '<div style="max-width: 650px;" data-ephox-embed-iri="https://askquran-media.s3-us-west-2.amazonaws.com/audio/ayah/khalifatnj/011118.mp3"><audio style="width: 100%;" controls="controls"> <source src="https://askquran-media.s3-us-west-2.amazonaws.com/audio/ayah/khalifatnj/011118.mp3" type="audio/mpeg"> Your browser does not support the audio element. </audio></div>',
              },
              {
                title: "Video",
                description: "watch the video",
                content:
                  '<div  data-ephox-embed-iri="https://atscontent.askquran.com/modules/23/kaana-wa-akhawaatuha-mobile.mp4"><video style="width: 100%;height:300px" controls="controls"> <source src="https://atscontent.askquran.com/modules/23/kaana-wa-akhawaatuha-mobile.mp4" type="video/mp4">Your browser does not support the video element.</video></div>',
              },
              {
                title: "New Table",
                description: "creates a new table",
                content:
                  '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
              },
              {
                title: "Starting my story",
                description: "A cure for writers block",
                content: "Once upon a time...",
              },
              {
                title: "New list with dates",
                description: "New List with dates",
                content:
                  '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
              },
            ],
            template_cdate_format:
              "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
            template_mdate_format:
              "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
            image_caption: true,
            toolbar_mode: "sliding",
          }}
          onEditorChange={(t) => setTitle(t)}
          style={{ padding: "8px", marginBottom: "15px" }}
        />
        <Typography style={{ color: "black", marginTop: "15px" }}>
          Choices
        </Typography>
        <div style={{
          display: "flex",
          justifyContent: "flex-end"
        }}>
          <ClearAllIcon onClick={handleDeleteAllOption} />
          <Tooltip title="Left to Right">
            <FormatTextdirectionRToLIcon
              style={{ cursor: "pointer" }}
              color={!direction ? "black" : 'action'}
              onClick={handleRightIcon} />
          </Tooltip>
          <Tooltip title="Right to Left">
            <FormatTextdirectionLToRIcon
              style={{ cursor: "pointer" }}
              color={direction ? "black" : 'action'}
              onClick={handleLeftIcon} />
          </Tooltip>
        </div>
        {stemText && options ? (
          <MarkupContent
            updateContentTextCallback={updateContentText}
            updateWordTypeCallback={updateWordType}
            deleteOptionCallback={deleteOption}
            options={options}
            text={stemText}
            direction={direction}
          ></MarkupContent>
        ) : (
          <Typography>Loading item...</Typography>
        )}
      </form>
    </div>
  );
}