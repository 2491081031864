export const ArabicGrammarTerms = {
  Basics: [
    {
      value: "الإضافة",
      label: "الإضافة",
    },
    {
      value: "المضاف",
      label: "المضاف",
    },
    {
      value: "المضاف إليه",
      label: "المضاف إليه",
    },
    {
      value: "حرف جر",
      label: "حرف جر",
    },
    {
      value: "اسم مجرور",
      label: "اسم مجرور",
    },
    {
      value: "جار ومجرور",
      label: "جار ومجرور",
    },
    {
      value: "اسم إشارة",
      label: "اسم إشارة",
    },
    {
      value: "اسم موصول",
      label: "اسم موصول",
    },
    {
      value: "صفة",
      label: "صفة",
    },
    {
      value: "فِعل",
      label: "فِعل",
    },
    {
      value: "اسم",
      label: "اسم",
    },
    {
      value: "اسم معرفة",
      label: "اسم معرفة",
    },
    {
      value: "اسم نكرة",
      label: "اسم نكرة",
    },
    {
      value: "اسم مذكَّر",
      label: "اسم مذكَّر",
    },
    {
      value: "اسم مؤنَّث",
      label: "اسم مؤنَّث",
    },
    {
      value: "المُثنَّى",
      label: "المُثنَّى",
    },
    {
      value: "جمع سالم",
      label: "جمع سالم",
    },
    {
      value: "جمع تكسير",
      label: "جمع تكسير",
    },
    {
      value: "جمع المذكر السالم",
      label: "جمع المذكر السالم",
    },
    {
      value: "جمع المؤنَّث السالم",
      label: "جمع المؤنَّث السالم",
    },
    {
      value: "حرف",
      label: "حرف",
    },
    {
      value: "حرف جازم",
      label: "حرف جازم - لا النَّهْيِ، لامُ الأَمْرِ، لَمْ، لَمَّا",
    },
    {
      value: "حرف ناصب",
      label: "حرف ناصب – لكي، أن، كي، حتى، لنْ، لام التعليل",
    },
  ],
  الجملة: [
    {
      value: "فاعل",
      label: "فاعل",
    },
    {
      value: "فعل وفاعل",
      label: "فعل وفاعل",
    },
    {
      value: "فعل وفاعل ومفعول به",
      label: "فعل وفاعل ومفعول به",
    },
    {
      value: "فعل ومفعول به",
      label: "فعل ومفعول به",
    },
    {
      value: "مُبتدأ",
      label: "مُبتدأ",
    },
    {
      value: "خبر",
      label: "خبر",
    },
    {
      value: "خبر شبه جملة",
      label: "خبر شبه جملة",
    },
    {
      value: "خبر مُفرد",
      label: "خبر مُفرد",
    },
    {
      value: "خبر جملة اسمية ",
      label: "خبر جملة اسمية ",
    },
    {
      value: "خبر جملة فعلية",
      label: "خبر جملة فعلية ",
    },
  ],
  المفاعيل: [
    {
      value: "مفعولٌ به",
      label: "مفعولٌ به",
    },
    {
      value: "مفعولٌ لأجله",
      label: "مفعولٌ لأجله",
    },
    {
      value: "ظرف",
      label: "ظرف",
    },
    {
      value: "ظرف زمان",
      label: "ظرف زمان",
    },
    {
      value: "ظرف مكان",
      label: "ظرف مكان",
    },
    {
      value: "المفعول المطلق",
      label: "المفعول المطلق",
    },
    {
      value: "مفعول مطلق لنَوْع",
      label: "مفعول مطلق لنَوْع",
    },
    {
      value: "مفعول مطلق لتأْكِيد",
      label: "مفعول مطلق لتأْكِيد",
    },
    {
      value: "نائب المفعول المطلق",
      label: "نائب المفعول المطلق",
    },
    {
      value: "مفعولٌ معه",
      label: "مفعولٌ معه",
    },
  ],
  Patterns: [
    {
      value: "Pattern-1",
      label: "Pattern-1",
    },
    {
      value: "Pattern-2",
      label: "Pattern-2",
    },
    {
      value: "Pattern-3",
      label: "Pattern-3",
    },
    {
      value: "Pattern-4",
      label: "Pattern-4",
    },
    {
      value: "Pattern-5",
      label: "Pattern-5",
    },
    {
      value: "Pattern-6",
      label: "Pattern-6",
    },
    {
      value: "Pattern-7",
      label: "Pattern-7",
    },
    {
      value: "Pattern-8",
      label: "Pattern-8",
    },
    {
      value: "Pattern-9",
      label: "Pattern-9",
    },
    {
      value: "Pattern-10",
      label: "Pattern-10",
    },
  ],
  الأفعال: [
    {
      value: "فعل ماض",
      label: "فعل ماض",
    },
    {
      value: "فعل سالِم",
      label: "فعل سالِم",
    },
    {
      value: "فعل مَهْمُوز",
      label: "فعل مَهْمُوز",
    },
    {
      value: "فعل مُضاعَف",
      label: "فعل مُضاعَف",
    },
    {
      value: "فعل مِثال",
      label: "فعل مِثال",
    },
    {
      value: "فعل أجْوَف",
      label: "فعل أجْوَف",
    },
    {
      value: "فعل ناقِص",
      label: "فعل ناقِص",
    },
    {
      value: "فعل مضارع",
      label: "فعل مضارع",
    },
    {
      value: "فعل أمر",
      label: "فعل أمر",
    },
    {
      value: "مضارع مجزوم",
      label: "مضارع مجزوم",
    },
    {
      value: "مضارع منصوب",
      label: "مضارع منصوب",
    },
    {
      value: "مضارع مرفوع",
      label: "مضارع مرفوع",
    },
  ],
  الأسماء: [
    {
      value: "مصدر",
      label: "مصدر",
    },

    {
      value: "جامد معنى",
      label: "جامد معنى",
    },
    {
      value: "مصدر ميمي",
      label: "مصدر ميمي",
    },
    {
      value: "اسم  مَكان",
      label: "اسم  مَكان",
    },
    {
      value: "اسم مَقْصُور",
      label: "اسم مَقْصُور",
    },
    {
      value: "جمع قِلَّة",
      label: "جمع قِلَّة",
    },
    {
      value: "اسم علَم",
      label: "اسم علَم",
    },
    {
      value: "النِسْبَة",
      label: "النِسْبَة",
    },
    {
      value: "اسم مَمْدُود",
      label: "اسم مَمْدُود",
    },
    {
      value: "اسم جمع ",
      label: "اسم جمع ",
    },
    {
      value: "مصدر مرة",
      label: "مصدر مرة",
    },
    {
      value: "اسم تَفْضِيل",
      label: "اسم تَفْضِيل",
    },
    {
      value: "صِيغة مُبالغة",
      label: "صِيغة مُبالغة",
    },
    {
      value: "صِفةُ مُشبهة",
      label: "صِفةُ مُشبهة",
    },
    {
      value: "جمع تكسير",
      label: "جمع تكسير",
    },
    {
      value: "جامد ذات",
      label: "جامد ذات",
    },
    {
      value: "مصدر هيئة",
      label: "مصدر هيئة",
    },
    {
      value: "اسم زَمان",
      label: "اسم زَمان",
    },
    {
      value: "اسم مَنْقُوص",
      label: "اسم مَنْقُوص",
    },
    {
      value: "اسم جنس",
      label: "اسم جنس",
    },
    {
      value: "اسم جنس جمعي",
      label: "اسم جنس جمعي",
    },
    {
      value: "اسم جنس إفرادي",
      label: "اسم جنس إفرادي",
    },
    {
      value: "اسم مشتق",
      label: "اسم مشتق",
    },
    {
      value: "اسم فاعِل",
      label: "اسم فاعِل",
    },
    {
      value: "اسم مَفْعول",
      label: "اسم مَفْعول",
    },
  ],
  "إنَّ وإخواتها": [
    {
      value: "إنَّ وأخواتها",
      label: "إنَّ وأخواتها",
    },
    {
      value: "اسم إنَّ",
      label: "اسم إنَّ",
    },
    {
      value: "خبر إنَّ",
      label: "خبر إنَّ",
    },
    {
      value: "اسم أنَّ",
      label: "اسم أنَّ",
    },
    {
      value: "خبر أنَّ",
      label: "خبر أنَّ",
    },
    {
      value: "اسم كَأنَّ",
      label: "اسم كَأنَّ",
    },
    {
      value: "خبر كَأنَّ",
      label: "خبر كَأنَّ",
    },
    {
      value: "اسم لَكِنَّ",
      label: "اسم لَكِنَّ",
    },
    {
      value: "خبر لَكِنَّ",
      label: "خبر لَكِنَّ",
    },
    {
      value: "اسم لَيْتَ",
      label: "اسم لَيْتَ",
    },
    {
      value: "خبر لَيْتَ",
      label: "خبر لَيْتَ",
    },
    {
      value: "اسم لَعَلَّ",
      label: "اسم لَعَلَّ",
    },
    {
      value: "خبر لَعَلَّ",
      label: "خبر لَعَلَّ",
    },
  ],
  "كان وأخواتها": [
    {
      value: "فعل ناسخ",
      label: "فعل ناسخ",
    },
    {
      value: "كان وأخواتها",
      label: "كان وأخواتها",
    },
    {
      value: "اسم كان",
      label: "اسم كان",
    },
    {
      value: "خبر كان",
      label: "خبر كان",
    },
    {
      value: "اسم أصبح",
      label: "اسم أصبح",
    },
    {
      value: "خبر أصبح",
      label: "خبر أصبح",
    },
    {
      value: "اسم أضحى",
      label: "اسم أضحى",
    },
    {
      value: "خبر أضحى",
      label: "خبر أضحى",
    },
    {
      value: "اسم أمسى",
      label: "اسم أمسى",
    },
    {
      value: "خبر أمسى",
      label: "خبر أمسى",
    },
    {
      value: "اسم بات",
      label: "اسم بات",
    },
    {
      value: "خبر بات",
      label: "خبر بات",
    },
    {
      value: "اسم ظلَّ",
      label: "اسم ظلَّ",
    },
    {
      value: "خبر ظلَّ",
      label: "خبر ظلَّ",
    },
    {
      value: "اسم صار",
      label: "اسم صار",
    },
    {
      value: "خبر صار",
      label: "خبر صار",
    },
    {
      value: "اسم ما دام",
      label: "اسم ما دام",
    },
    {
      value: "خبر ما دام",
      label: "خبر ما دام",
    },
    {
      value: "اسم ليس",
      label: "اسم ليس",
    },
    {
      value: "خبر ليس",
      label: "خبر ليس",
    },
    {
      value: "اسم ما زال",
      label: "اسم ما زال",
    },
    {
      value: "خبر ما زال",
      label: "خبر ما زال",
    },
    {
      value: "اسم ما برح",
      label: "اسم ما برح",
    },
    {
      value: "خبر ما برح",
      label: "خبر ما برح",
    },
    {
      value: "اسم ما انفك",
      label: "اسم ما انفك",
    },
    {
      value: "خبر ما انفك",
      label: "خبر ما انفك",
    },
  ],
  الحال: [
    {
      value: "الحال",
      label: "الحال",
    },
    {
      value: "صاحبِ الحال",
      label: "صاحبِ الحال",
    },
    {
      value: "الحال الْمُفرد",
      label: "الحال الْمُفرد - فحصَ الطبيبُ واقِفاً",
    },
    {
      value: "الحال شِبْهُ جملة",
      label: "الحال شِبْهُ جملة - رأيتُ الهلالَ بينَ السِّحاب",
    },
    {
      value: "الحال جملة فعلية",
      label: "الحال جملة فعلية - شاهدت المُزارِعَ يَحْصُد القمح",
    },
    {
      value: "الحال جملة اسمية",
      label: "الحال جملة اسمية - رأيتُ زيدا كتابُهُ في يمينه ،وهو واقِف",
    },
    {
      value: "صاحبِ الحال مبتدأ",
      label: "صاحبِ الحال مبتدأ",
    },
    {
      value: "صاحبِ الحال خبر",
      label: "صاحبِ الحال خبر",
    },
    {
      value: "صاحبِ الحال نائب الفاعل",
      label: "صاحبِ الحال نائب الفاعل",
    },
    {
      value: "صاحبِ الحال اسم مجرور",
      label: "صاحبِ الحال اسم مجرور",
    },
    {
      value: "صاحبِ الحال مفعول لأجله",
      label: "صاحبِ الحال مفعول لأجله",
    },
    {
      value: "صاحبِ الحال الفاعل",
      label: "صاحبِ الحال الفاعل",
    },
    {
      value: "صاحبِ الحال فاعل ومفعول به",
      label: "صاحبِ الحال فاعل ومفعول به",
    },
    {
      value: "صاحبِ الحال المفعول فيه",
      label: "صاحبِ الحال المفعول فيه",
    },
    {
      value: "صاحبِ الحال مفعول مطلق",
      label: "صاحبِ الحال مفعول مطلق",
    },
    {
      value: "صاحبِ الحال مفعول به",
      label: "صاحبِ الحال مفعول به",
    },
  ],
  "فعل للمَجْهول": [
    {
      value: "فعل للمَجْهول",
      label: "فعل للمَجْهول",
    },
    {
      value: "نائب الفاعل",
      label: "نائب الفاعل",
    },
    {
      value: "نائب الفاعل ضمير مُسْتتر",
      label: "نائب الفاعل ضمير مُسْتتر",
    },
    {
      value: "نائب الفاعل ضمير مُتَّصِل",
      label: "نائب الفاعل ضمير مُتَّصِل",
    },
  ],
  "أداة استثناء": [
    {
      value: "أداة استثناء",
      label: "أداة استثناء - إلَّا،خلا،حاشا، عدا، غير، سوى",
    },
    {
      value: "المُسْتَثْنى منه",
      label: "المُسْتَثْنى منه",
    },
    {
      value: "المُسْتَثْنى",
      label: "المُسْتَثْنى",
    },
    {
      value: "المُسْتَثْنى المتصل",
      label: "المُسْتَثْنى المتصل - حضرَ الرجال إلا عَلِيًّا",
    },
    {
      value: "المُسْتَثْنى المنقطع",
      label: "المُسْتَثْنى المنقطع - حضرالضُّيُوفُ  إلا سيَّاراتِهم",
    },
  ],
  "لا النافية للجنس": [
    {
      value: "لا النافية للجنس",
      label: "لا النافية للجنس",
    },
    {
      value: "اسم لا",
      label: "اسم لا",
    },
    {
      value: "خبر لا",
      label: "خبر لا",
    },
    {
      value: "اسم لا مفرد",
      label: "اسم لا مفرد",
    },
    {
      value: "اسم لا مضاف",
      label: "اسم لا مضاف",
    },
    {
      value: "اسم لا شبيه بالمضاف",
      label: "اسم لا شبيه بالمضاف",
    },
  ],
  التمييز: [
    {
      value: "التمييز",
      label: "التمييز",
    },
    {
      value: "التمييز الملفوظ",
      label: "التمييز الملفوظ - يفسر الاسم: عندي خمسةَ عشَرَ دفترًا",
    },
    {
      value: "التمييز الملحوظ",
      label: "التمييز الملحوظ - يفسر الجملة: محمد أكبر مني سِناً",
    },
  ],
  "ظَنَّ وأخواتها": [
    {
      value: "ظَنَّ وأخواتها",
      label: "ظَنَّ وأخواتها",
    },
    {
      value: "أفعال القلوب",
      label:
        "أفعال القلوب - رَأَى،عَلِمَ وَجَدَ، دَرَى،ظَنَّ، خالَ، حَسِبَ، زَعَمَ، عَدَّ، حَجَا، هَبْ",
    },
    {
      value: "أفعال القلوب لليقين",
      label: "أفعال القلوب لليقين - رَأَى،عَلِمَ وَجَدَ، دَرَى",
    },
    {
      value: "أفعال القلوب للرُّجحان",
      label:
        "أفعال القلوب للرُّجحان - ظَنَّ، خالَ، حَسِبَ، زَعَمَ، عَدَّ، حَجَا، هَبْ",
    },
    {
      value: "أفعال تحويل",
      label:
        "أفعال تحويل - جَعَلَ، صَيَّرَ، وَهَبَ، اِتَّخَذَ ، ترك بمعنى طرح، رد",
    },
    {
      value: "مفعول به أوّل",
      label: "مفعول به أوّل",
    },
    {
      value: "مفعول به ثان",
      label: "مفعول به ثان",
    },
  ],
  "كاد وأخواتها": [
    {
      value: "كاد وأخواتها",
      label: "كاد وأخواتها",
    },
    {
      value: "كاد وأخواتها للْمُقاربة",
      label: "كاد وأخواتها للْمُقاربة - كادَ، أَوْشَكَ، كرَبَ",
    },
    {
      value: "اسم كادَ",
      label: "اسم كادَ",
    },
    {
      value: "خبر كادَ",
      label: "خبر كادَ",
    },
    {
      value: "كاد وأخواتها للرَّجاء",
      label: "كاد وأخواتها للرَّجاء - عَسى، حَرَى، اِخْلَوْلَقَ",
    },
    {
      value: "اسم عَسى",
      label: "اسم عَسى",
    },
    {
      value: "خبر عَسى",
      label: "خبر عَسى",
    },
    {
      value: "اسم حَرَى",
      label: "اسم حَرَى",
    },
    {
      value: "خبر حَرَى",
      label: "خبر حَرَى",
    },
    {
      value: "اسم اِخْلَوْلَقَ",
      label: "اسم اِخْلَوْلَقَ",
    },
    {
      value: "خبر اِخْلَوْلَقَ",
      label: "خبر اِخْلَوْلَقَ",
    },
    {
      value: "كاد وأخواتها للشروع",
      label:
        "كاد وأخواتها للشروع - جَعَلَ، أَخَذَ، أَنْشَأَ، شرَعَ، طَفِقَ، هَبَّ، بَدَأ، قام",
    },
    {
      value: "اسم جَعَلَ",
      label: "اسم جَعَلَ",
    },
    {
      value: "خبر جَعَلَ",
      label: "خبر جَعَلَ",
    },
    {
      value: "اسم أَخَذَ",
      label: "اسم أَخَذَ",
    },
    {
      value: "خبر أَخَذَ",
      label: "خبر أَخَذَ",
    },
    {
      value: "اسم أَنْشَأَ",
      label: "اسم أَنْشَأَ",
    },
    {
      value: "خبر أَنْشَأَ",
      label: "خبر أَنْشَأَ",
    },

    {
      value: "اسم شرَعَ",
      label: "اسم شرَعَ",
    },
    {
      value: "خبر شرَعَ",
      label: "خبر شرَعَ",
    },
  ],
  "أسلوب النداء": [
    {
      value: "أداة النداء",
      label: "أداة النداء",
    },
    {
      value: "منادى",
      label: "منادى",
    },
    {
      value: "منادى مضاف",
      label: "منادى مضاف - يا ربَّ العالمين",
    },
    {
      value: "منادى شَبِيه بالمضاف",
      label: "منادى شَبِيه بالمضاف - يا طالعاً جبلاً",
    },
    {
      value: "منادى نكرة غير مقصودة",
      label: "منادى نكرة غير مقصودة - يا رجلاً",
    },
    {
      value: "منادى نكرة مقصودة",
      label: "منادى نكرة مقصودة - يا رجلُ، يا مسلمون",
    },
    {
      value: "منادى علم مفرد",
      label: "منادى علم مفرد - يا كريمُ",
    },
  ],
  "اسم فعل": [
    {
      value: "اسم فعل",
      label: "اسم فعل",
    },
    {
      value: "اسم فعل الماضي",
      label: "اسم فعل الماضي",
    },
    {
      value: "اسم فعل الأمر",
      label: "اسم فعل الأمر",
    },
    {
      value: "اسم فعل المضارع",
      label: "اسم فعل المضارع",
    },
  ],
};
