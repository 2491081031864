import React from "react";
import { useTimer } from "react-timer-hook";
import { zeroPad } from "../../utils/Utils";
function ActivityTimer({ expiryTimestamp, callBackOnTimesUp }) {
  const { seconds, minutes, hours } = useTimer({
    expiryTimestamp,
    onExpire: () => callBackOnTimesUp(),
  });

  return (
    <div>
      <div
        style={{
          fontWeight: "bold",
          fontFamily: "Roboto Mono, monospace",
          fontSize: "28px",
          color: "rgb(159, 201, 116)",
        }}
      >
        <span> {hours}</span>
        <span style={{ fontSize: "10px", paddingRight: "3px" }}>h</span>
        <span>{zeroPad(minutes, 2)}</span>
        <span style={{ fontSize: "10px", paddingRight: "3px" }}>m</span>
        <span>{zeroPad(seconds, 2)}</span>
        <span style={{ fontSize: "10px", paddingRight: "3px" }}>s</span>
      </div>
    </div>
  );
}

export default ActivityTimer;
