import React, { Fragment, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import AuthService from "../utils/AuthService";
import axios from "axios";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import { Markup } from "interweave";
import Box from "@material-ui/core/Box";
import MobileStepper from "@material-ui/core/MobileStepper";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Switch from "@material-ui/core/Switch";
import AtsBreadCrumbs from "./AtsBreadCrumbs";
import Authorized from "./Authorized";
import AqProgress from "./AqProgress";
import ApiMap from "../utils/ApiMap";

import ActivityTimer from "./Activities/ActivityTimer";
import CourseServices from "../apis/CourseServices";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 345,
    width: "99%",
    margin: "0 auto",
    fontSize: "large",
    boxShadow: "none",
    border: "0px solid #DEDEDE",
  },
  paper: {
    margin: theme.spacing(1),
  },
  maincontent: {
    fontFamily: "Montserrat, serif",
    fontSize: "large",
    marginBottom: "25px",
    paddingBottom: "25px",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  title: {
    fontFamily: "chewy",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  confrimdlg: {
    right: 0,
    bottom: 50,
    zIndex: 10000,
    position: "fixed",
    float: "left",
    width: "150px",
  },
  margin: {
    width: "50px",
  },
}));

export default function Reader(props) {
  console.log("Reader:" + props);
  const classes = useStyles();
  const theme = useTheme();
  const chapterinfo = React.useRef(null);
  const [chapter, setChapter] = React.useState(null);
  let { contentId, courseId, chapterId, moduleId } = useParams();
  const [completed, setCompleted] = React.useState(false);
  const [loadingNxtPg, setLoadingNxtPg] = React.useState(false);
  const [loadingPrevPg, setLoadingPrevPg] = React.useState(false);

  const [showConfirm, setShowConfirm] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = React.useRef(1);
  const [lastLesson, setLastLesson] = React.useState(false);
  const [nextModuleCid, setnextModuleCid] = React.useState(null);
  const [timesUp, setTimesUp] = React.useState(false);
  if (props.preview) {
    contentId = props.contentId;
    courseId = props.courseId;
    moduleId = props.moduleId;
    chapterId = props.chapterId;
  }

  const handleNext = () => {
    if (chapterinfo.current.CurPgNum < chapterinfo.current.TotalPages) {
      setLoadingNxtPg(true);
      getChapter(parseInt(chapterinfo.current.CurPgNum) + 1);
    }
  };

  const markChapter = async () => {
    let resp;
    if (completed) {
      resp = await CourseServices.markLessonInComplete(courseId, moduleId, chapterId, contentId);
    } else {
      resp = await CourseServices.markLessonComplete(courseId, moduleId, chapterId, contentId);
    }
    if (resp.status === 200) {
      setCompleted(!completed);
    }
    setShowConfirm(false);
  };

  const showConfirmFlag = () => {
    setShowConfirm(!showConfirm);
  };

  const handleBack = () => {
    // setActiveStep(chapterinfo.current.CurPgNum);
    setLoadingPrevPg(true);
    getChapter(chapterinfo.current.CurPgNum - 1);

  };
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios(
        `${ApiMap.CONTENT}/coursecontent/${courseId}/module/${moduleId}/chapter/${chapterId}/lesson/${contentId}`,
        AuthService.getAuthHeader()
      );
      chapterinfo.current = resp.data;
      var c = {};
      c.Name = chapterinfo.current.Name;
      c.Title = chapterinfo.current.Title;
      c.Value = chapterinfo.current.Value;
      c.ContentFormat = chapterinfo.current.ContentFormat;
      c.Time = chapterinfo.current.DurationMin;
      maxSteps.current = chapterinfo.current.TotalPages;
      if (chapterinfo.current.DateCompleted) {
        setCompleted(true);
      }
      setChapter(c);
    };
    if (AuthService.isLoggedIn()) {
      fetchData();
    }
  }, [contentId]);

  if (!AuthService.isLoggedIn()) {
    return <Authorized refRoute="Courses" />;
  }
  const getChapter = async (pgNum) => {
    const resp = await axios(
      `${ApiMap.CONTENT}/coursecontent/${courseId}/module/${moduleId}/chapter/${chapterId}/lesson/${contentId}?pgnum=${pgNum}`,
      AuthService.getAuthHeader()
    );
    setLoadingNxtPg(false);
    setLoadingPrevPg(false);
    var c = {};
    c.Name = resp.data.Name;
    c.Title = resp.data.Title;
    c.Value = resp.data.Value;
    chapterinfo.current.CurPgNum = resp.data.CurPgNum;
    console.log(chapterinfo.current.CurPgNum);
    setActiveStep(chapterinfo.current.CurPgNum);
    c.ContentFormat = resp.data.ContentFormat;
    setChapter(c);
  };

  if (!chapter) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="center"
        p={1}
        m={3}
        bgcolor="background.paper"
      >
        <AqProgress></AqProgress>
      </Box>
    );
  }

  function loadNextLesson() {
    console.log("Load  Next lesson");
    const fetchNextLesson = async () => {
      try {
        const resp = await axios(
          `${AuthService.API_BASE_URL}api/item/nextlesson/course/${courseId}/module/${moduleId}/lesson/${contentId}`,
          AuthService.getAuthHeader()
        );
        console.log(resp.data);
        if (resp.data.NextModuleCid) {
          setnextModuleCid(resp.data.NextModuleCid);
          setLastLesson(true);
        } else {
          var nextLessonUrl = `/Reader/${courseId}/modulecid/${resp.data.ModuleCid}/lesson/${resp.data.LessonCid}`;
          if (resp.data.LessonType === "Assignment") {
            nextLessonUrl = `/Assignment/${courseId}/modulecid/${resp.data.ModuleCid}/lesson/${resp.data.LessonCid}`;
          }
          window.location.href = nextLessonUrl;
        }
      } catch (err) {
        if (err.response.status === 404) {
          setLastLesson(true);
        }
      }
    };
    fetchNextLesson();
  }
  function loadNextModule() {
    var nextModUrl = `/Lessons/${courseId}/Module/${nextModuleCid}`;
    window.location.href = nextModUrl;
  }
  function NextModule() {
    return (
      <div>
        {" "}
        <Typography className={classes.instructions}>
          All lessons finished, Onwards!
          <Button
            style={{ marginLeft: "15px" }}
            color="primary"
            variant="contained"
            onClick={loadNextModule}
          >
            Next Module!
          </Button>
        </Typography>
      </div>
    );
  }

  function NextButton() {
    console.log(chapterinfo.current);
    if (
      AuthService.isFaculty(courseId) &&
      (activeStep === maxSteps.current - 1 ||
        chapterinfo.current.ContentType === "Game")
    ) {
      if (props.preview) {
        return (
          <Button size="small" onClick={() => props.closePreview()}>
            Close
          </Button>
        );
      } else {
        return (
          <Fragment>
            <FormControlLabel
              control={
                <Switch
                  checked={completed}
                  onClick={showConfirmFlag}
                  value="Done"
                />
              }
              label="Done"
            />
            <Button size="small" onClick={loadNextLesson} disabled={!completed}>
              Next
              <KeyboardArrowRight />
            </Button>
          </Fragment>
        );
      }
    }
    if (loadingNxtPg) {
      return (
        <div>
          < Typography > Loading...</Typography >
          <LinearProgress color="secondary" />
        </div >)
    }
    return (

      <Button
        size="small"
        onClick={handleNext}
        disabled={chapterinfo.current.CurPgNum >= chapterinfo.current.TotalPages}
      >
        Next
        <KeyboardArrowRight />
      </Button>
    );
  }

  function CallMeOnTimesUp() {
    setTimesUp(true);
  }

  const myCourseLink = "/Courses";
  const courseBrdcmbLnk = "/Modules/Course/" + courseId;
  const moduleBrdcmbLnk = `/Lessons/${courseId}/Module/${moduleId}`;

  const breadcrumbs = [
    { title: "My Courses", link: myCourseLink },
    { title: `${chapterinfo.current.CourseName}`, link: courseBrdcmbLnk },
    { title: `${chapterinfo.current.ModuleName}`, link: moduleBrdcmbLnk },
  ];

  function GameOverMsg() {
    return (
      <Fragment>
        <div style={{ color: "red", fontSize: "36px" }}>Times Up!</div>{" "}
      </Fragment>
    );
  }
  function ChapterTitle({ chapterTitle, lessonTime }) {
    const time = new Date();
    time.setSeconds(time.getSeconds() + lessonTime * 60);
    if (timesUp) {
      return <GameOverMsg></GameOverMsg>;
    }
    return (
      <Fragment>
        <ActivityTimer
          callBackOnTimesUp={CallMeOnTimesUp}
          expiryTimestamp={time}
        />
        <Markup className={classes.title} content={chapterTitle} />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <AtsBreadCrumbs last={chapter.Name} crumbs={breadcrumbs}></AtsBreadCrumbs>
      <Card className={classes.root}>
        <CardHeader
          title={
            <ChapterTitle
              lessonTime={chapter.Time}
              chapterTitle={chapter.Title}
            />
          }
          subheader={chapter.Name}
        />
        <CardContent className={classes.maincontent}>
          <Markup content={chapter.Value} />
        </CardContent>
        <Fade in={showConfirm}>
          <FormControl className={classes.confrimdlg}>
            Are you sure?
            <Button
              onClick={markChapter}
              size="small"
              variant="outlined"
              className={classes.margin}
              color="primary"
            >
              Yes
            </Button>
          </FormControl>
        </Fade>
        <MobileStepper
          steps={maxSteps.current}
          position="bottom"
          variant="text"
          activeStep={chapterinfo.current.CurPgNum - 1}
          style={{ backgroundColor: "darkcyan" }}
          nextButton={
            !lastLesson ? <NextButton></NextButton> : <NextModule></NextModule>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={chapterinfo.current.CurPgNum == 1}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
      </Card>
    </Fragment>
  );
}
