import React, { useState, Fragment } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Slide from "@material-ui/core/Slide";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import { Grid } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "transparent",
  },
  grow: { flexGrow: 1 },
  wordtype: {
    fontSize: "x-large",
    direction: "rtl",
    borderBottom: "1px dashed #E1E1E1",
    width: "100%",
    textAlign: "right",
    color: "#0000ff",
  },
}));

export function MarkupWordTypes({
  open,
  dict,
  handleCloseCallback,
  handleWordTypeSelectionCallback,
  word,
  wkey
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.up("xs"));
  const [wordTypeValue, setWordTypeValue] = React.useState();
  const classes = useStyles();
  const [showmsg, setShowmsg] = useState(false);
  console.log(wordTypeValue, dict)
  function updateWordType() {
    if (!wordTypeValue) {
      setShowmsg(true);
      return;
    }
    handleWordTypeSelectionCallback(wordTypeValue);
  }
  const handleClose = () => {
    handleCloseCallback();
  };

  function SlideTransition(props) {
    return <Slide {...props} direction="right" />;
  }
  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showmsg}
        autoHideDuration={5000}
        TransitionComponent={SlideTransition}
        message={"word type not selected"}
        key={SlideTransition.name}
        onClose={() => setShowmsg(false)}
      />
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullScreen
        open={open}
        onClose={handleClose}
      >
        <AppBar
          position="fixed"
          className={classes.appBar}
          style={{ boxShadow: "none" }}
        >
          <Toolbar>

            <Typography>Select Word Type for: </Typography>
            <Typography variant='h4' color='secondary'> {word[wkey - 1]}</Typography>
            <div className={classes.grow}></div>
            <Button
              variant="contained"
              onClick={() => updateWordType()}
              color="primary"
              size="small"
              style={{ marginRight: "15px" }}
            >
              Ok
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={handleClose}
              color="default"
            >
              Cancel
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid container>
            <Grid xs={12} sm={3} style={{ padding: "15px" }} item>
              <TextField
                id="freeform-wordtype"
                label="Enter word type"
                style={{ width: "100%" }}
                helperText="freeform word type"
                onChange={(e) => setWordTypeValue(e.target.value.trim())}
              />
            </Grid>
            {Object.keys(dict).map(function (groupname, i) {
              return (
                <Grid xs={12} sm={3} style={{ padding: "15px" }} item>
                  <TextField
                    select
                    label={groupname}
                    style={{ width: "100%" }}
                    onChange={(e) => setWordTypeValue(e.target.value.trim())}
                  >
                    {dict[groupname].map((option) => (
                      <MenuItem
                        style={{ direction: "rtl" }}
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}