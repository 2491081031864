import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import List from "@material-ui/core/List";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import AuthService from "../../utils/AuthService";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
//import AddIcon from "@material-ui/icons/Add";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Grid from "@material-ui/core/Grid";
import Reader from "../Reader";
//import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DeleteIcon from "@material-ui/icons/Delete";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import AtsBreadCrumbs from "../AtsBreadCrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import ApiMap from "../../utils/ApiMap";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",

    backgroundColor: "transparent",
  },
  grow: { flexGrow: 1 },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    textAlign: "center",
  },
  arbtext: {
    fontSize: 24,
    padding: "3px",
    fontFamily: "'Traditional Arabic',Lateef",
    fontWeight: 500,
    color: "blue",
    lineHeight: 1,
    direction: "rtl",
  },
  engtext: {
    fontSize: "large",
    padding: "3px",
    fontWeight: 500,
    color: "blue",
    lineHeight: 1,
    direction: "ltr",
  },
  form: {
    padding: "7px",
    margin: "7px",
  },
  fabButton: {
    width: "38px",
    height: "35px",
  },
  colhdr: {
    textAlign: "center",
    fontSize: "x-large",
    backgroundColor: "#607D8B",
    border: 0,
  },
  aCol: {
    height: "300px",
    overflow: "auto",
  },
  settingsText: {
    height: "35px",
    padding: "3px",
    textAlign: "center",
    fontSize: "x-large",
    border: "0px solid #333333",
  },
}));

const AssignmentTextField = withStyles({
  root: {
    "& .MuiInput-underline:before": {
      borderBottom: "1px dashed #888",
    },
    "& .MuiInput-underline:hover::before": {
      borderBottom: `1px dashed darkorange`,
    },
    "& .MuiInput-underline::after": {
      borderBottom: `0px solid #ff6e40`,
    },
  },
})(TextField);

const HdrColTextField = withStyles({
  root: {
    "& .MuiInput-underline:before": {
      borderBottom: "1px dashed #888",
    },
    "& .MuiInput-underline:hover::before": {
      borderBottom: `0px dashed darkorange`,
    },
    "& .MuiInput-underline::after": {
      borderBottom: `0px solid #ff6e40`,
    },
  },
})(TextField);

function AssignmentRows(props) {
  // console.log("rows", props.rows);
  const classes = useStyles();
  const [rows, setRows] = useState(null);

  useEffect(() => {
    setRows(props.rows);
  }, [props.rows]);

  function updateVal(e, rowno, colno) {
    props.updtCallback(e.target.value, rowno, colno);
  }

  function addRowBelow(rowno) {
    props.addRowBelowCallback(props.colno, rowno);
  }
  function addRowAbove(rowno) {
    props.addRowAboveCallback(props.colno, rowno);
  }
  function deleteRow(rowno) {
    props.delRowCallback(rowno, props.colno);
  }

  if (rows === null) {
    return null;
  }
  var dirsettings = classes.arbtext;
  if (props.settings.direction === "ltr") {
    dirsettings = classes.engtext;
  }
  return (
    <div>
      {rows.map(function (row, r) {
        var key = Date.now() + Math.floor(Math.random() * 10000);

        return (
          <div key={key}>
            <AssignmentTextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Tooltip
                      disableFocusListener
                      arrow="true"
                      TransitionComponent={Zoom}
                      title="Add entry above"
                    >
                      <AddCircleOutlineIcon
                        onClick={() => addRowAbove(r)}
                        style={{ cursor: "pointer", color: "#607d8bab" }}
                      />
                    </Tooltip>
                    <Tooltip
                      disableFocusListener
                      arrow="true"
                      TransitionComponent={Zoom}
                      title="Delete entry"
                    >
                      <DeleteIcon
                        style={{ cursor: "pointer", color: "#607d8bab" }}
                        onClick={() => deleteRow(r)}
                      />
                    </Tooltip>
                    <Tooltip
                      disableFocusListener
                      arrow="true"
                      TransitionComponent={Zoom}
                      title="Add entry below"
                    >
                      <AddCircleOutlineIcon
                        onClick={() => addRowBelow(r)}
                        style={{ cursor: "pointer", color: "#607d8bab" }}
                      />
                    </Tooltip>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">{r + 1}</InputAdornment>
                ),
                classes: {
                  input: dirsettings,
                },
              }}
              onChange={(e) => updateVal(e, r, props.colno)}
              defaultValue={row}
              style={{ width: "100%" }}
            ></AssignmentTextField>
          </div>
        );
      })}
    </div>
  );
}
function ColSettings({ data, col, updateSettingsCallback }) {
  const classes = useStyles();
  const [coldata, setColdata] = useState(data);
  function setColwidth(w) {
    data.width = w;
  }
  function handleChange(w) {
    let c = { ...coldata };

    if (c.direction === "ltr") {
      c.direction = "rtl";
    } else {
      c.direction = "ltr";
    }
    data.direction = c.direction;
    setColdata(c);
    updateSettingsCallback(c, col);
  }
  return (
    <Grid container spacing={1}>
      <Grid item xs={6} style={{ padding: "1px" }}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          id={"colwidth_" + col}
          type="number"
          key={"colwidth_" + col}
          label="Width"
          name="Width"
          defaultValue={data.width}
          fullWidth
          autoComplete="off"
          inputProps={{
            max: 100,
            min: 10,
          }}
          InputProps={{
            inputProps: {
              max: 100,
              min: 10,
            },
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
            classes: { input: classes.settingsText },
          }}
          style={{
            display: "block",
            padding: "5px",
          }}
          onChange={(e) => setColwidth(e.target.value, col)}
        />
      </Grid>
      <Grid item xs={6} style={{ padding: "1px" }}>
        <FormControlLabel
          control={
            <Switch
              checked={coldata.direction === "ltr"}
              onChange={handleChange}
              name="coldirection"
            />
          }
          label="LTR"
        />
      </Grid>
    </Grid>
  );
}

function AssignmentColumn(props) {
  console.log("cols");
  const classes = useStyles();
  const [assignment, setAssignment] = useState(props.content);

  function updateCell(data, rowno, colno) {
    props.updateCellCallback(data, rowno, colno);
  }

  function updateColTitle(e, col) {
    /* let newArr = { ...assignment };
    newArr.columns[col].name = e.target.value;
    setAssignment(newArr); */
    props.updateColTitleCallback(col, e.target.value);
  }
  function deleteRow(r, c) {
    let newArr = { ...assignment };
    var filtered = newArr.data[c].filter(function (value, index) {
      return index !== r;
    });
    newArr.data[c] = filtered;
    setAssignment(newArr);
  }

  function addRowBelow(col, row, above) {
    let newArr = { ...assignment };
    newArr.data[col].splice(row + 1, 0, "");
    setAssignment(newArr);
  }

  function addRowAbove(col, row, above) {
    let newArr = { ...assignment };
    newArr.data[col].splice(row, 0, "");
    setAssignment(newArr);
  }

  function addColToRight(col) {
    let newArr = { ...assignment };
    newArr.columns.splice(col + 1, 0, { name: "column header" });
    newArr.data.splice(col + 1, 0, [""]);
    setAssignment(newArr);
  }

  function deleteCol(col) {
    let newArr = { ...assignment };
    var newcols = newArr.columns.filter(function (c, i) {
      return i !== col;
    });
    var newrows = newArr.data.filter(function (c, i) {
      return i !== col;
    });
    newArr.columns = newcols;
    newArr.data = newrows;
    setAssignment(newArr);
    props.deleteColCallback(col);
  }

  function addColToLeft(col) {
    let newArr = { ...assignment };
    newArr.columns.splice(col, 0, { name: "column header" });
    newArr.data.splice(col, 0, [""]);
    setAssignment(newArr);
  }

  function updateSettings(data, col) {
    let newArr = { ...assignment };
    newArr.columns[col] = data;
    setAssignment(newArr);
  }

  return assignment.columns.map(function (c, i) {
    var colkey =
      Date.now() +
      Math.floor(Math.random() * 10000) +
      Math.floor(Math.random() * 10000);
    var colwidth = Math.round(12 / assignment.columns.length);
    if (colwidth < 2) {
      colwidth = 2;
    }
    return (
      <Grid
        key={colkey}
        item
        xs={12}
        md={colwidth}
        style={{ border: "1px solid #9E9E9E", padding: "1px" }}
      >
        <Grid container style={{ backgroundColor: "#607D8B" }}>
          <Grid item sm={1} xs={2} style={{ paddingTop: "10px" }}>
            {i === 0 ? (
              <Tooltip
                disableFocusListener
                arrow="true"
                TransitionComponent={Zoom}
                title="Add column to left"
              >
                <AddCircleOutlineIcon
                  onClick={() => addColToLeft(i)}
                  style={{
                    cursor: "pointer",
                    color: "#FFEB3B",
                  }}
                />
              </Tooltip>
            ) : null}
          </Grid>
          <Grid item sm={10} xs={8}>
            <HdrColTextField
              InputProps={{
                classes: {
                  input: classes.colhdr,
                },
              }}
              onChange={(e) => updateColTitle(e, i)}
              defaultValue={c.name}
              style={{ width: "100%", backgroundColor: "#607D8B" }}
            ></HdrColTextField>
          </Grid>
          <Grid item sm={1} xs={3} style={{ paddingTop: "10px" }}>
            <Tooltip
              disableFocusListener
              arrow="true"
              TransitionComponent={Zoom}
              title="Delete column"
            >
              <DeleteIcon
                onClick={() => deleteCol(i)}
                style={{
                  cursor: "pointer",
                  color: "#00BCD4",
                }}
              />
            </Tooltip>
            <Tooltip
              disableFocusListener
              arrow="true"
              TransitionComponent={Zoom}
              title="Add column to right"
            >
              <AddCircleOutlineIcon
                onClick={() => addColToRight(i)}
                style={{
                  cursor: "pointer",
                  color: "#FFEB3B",
                }}
              />
            </Tooltip>
          </Grid>
        </Grid>

        <div className={classes.aCol}>
          <List dense={true}>
            <AssignmentRows
              colno={i}
              settings={c}
              updtCallback={updateCell}
              rows={assignment.data[i]}
              delRowCallback={deleteRow}
              addRowAboveCallback={addRowAbove}
              addRowBelowCallback={addRowBelow}
            ></AssignmentRows>
          </List>
        </div>
        <div style={{ padding: "3px", backgroundColor: "#607D8B" }}>
          <ColSettings
            column={i}
            data={c}
            updateSettingsCallback={updateSettings}
          ></ColSettings>
        </div>
      </Grid>
    );
  });
}

var sample = {
  columns: [
    { name: "Arabic", style: "cntr", direction: "rtl", width: "30" },
    { name: "English", style: "cntr", direction: "ltr", width: "40" },
    { name: "Teacher's Comment", style: "cntr", direction: "ltr", width: "20" },
  ],
  data: [["السَيَّارَةُ الزَرْقَاءُ"], [""], [""]],
};
export default function AssignmentEditor() {
  console.log("AssignmentEditor");
  let {courseid, modcid, chapId,lsnId } = useParams();
  const classes = useStyles();
  const [content, setContent] = React.useState(null);
  const [lines, setLines] = React.useState(1);
  const [updating, setUpdating] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);

  // if (content === null) {
  //   setContent(sample);
  // }
  useEffect(() => {
    var url = `${ApiMap.ASSIGNMENT_CONTENT}/${courseid}/module/${modcid}/chapter/${chapId}/lesson/${lsnId}`;
    const fetchData = async () => {
      const resp = await axios(url, AuthService.getAuthHeader());
      console.log(resp.data);
      let asContent;
      if (resp.data.columns)  {
        asContent= {
          columns: resp.data.columns,
          data: resp.data.data
        }
      } else {
        asContent = sample;
      }
      setContent(asContent);
      setLines(resp.data.lines)
    };
    if (!content) {
      fetchData();
    }
  });

  const closePreviewPage = () => {
    setOpenPreview(false);
  };
  const previewPage = () => {
    setOpenPreview(!openPreview);
  };

  const saveContent = (e) => {
    e.preventDefault();
    const contentObj = {
      lines: lines,
      ...content
    };
    var url = `${ApiMap.ASSIGNMENT_CONTENT}/${courseid}/module/${modcid}/chapter/${chapId}/lesson/${lsnId}`;
    setUpdating(true);
    async function update() {
      try {
        const response = await axios.post(
          url,
          contentObj,
          AuthService.getAuthHeader()
        );
        if (response.status === 200) {
          setUpdating(false);
        }
      } catch (err) {
        console.log(err);
      }
    }
    update();
  };

  function updateAssignment(data, rowno, colno) {
    content.data[colno][rowno] = data;
  }
  function updateColTitle(colno, title) {
    content.columns[colno].name = title;
  }

  function deleteColumn(col) {
    let newArr = { ...content };
    var newcols = newArr.columns.filter(function (c, i) {
      return i !== col;
    });
    var newrows = newArr.data.filter(function (c, i) {
      return i !== col;
    });
    newArr.columns = newcols;
    newArr.data = newrows;
    setContent(newArr);
  }

  function deleteRow(rowno, colno) {
    let newArr = { ...content };

    var filtered = newArr.data[colno].filter(function (value, index, arr) {
      return index !== rowno;
    });
    newArr.data[colno] = filtered;
    setContent(newArr);
  }
  const moduleBrdcmbLnk = "/Modules/Course/" + courseid;
  const lessonBrdcmbLnk = "/Lessons/" + courseid + "/Module/" + modcid;
  const breadcrumbs = [
    { title: "Module", link: moduleBrdcmbLnk },
    { title: "Lesson", link: lessonBrdcmbLnk },
  ];
  if (!content) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="center"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Typography>Loading assignment...</Typography>
        <LinearProgress color="secondary" />
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={classes.appBar}
        style={{ boxShadow: "none" }}
      >
        <Toolbar>
          <AtsBreadCrumbs
            last={"Assignment"}
            crumbs={breadcrumbs}
          ></AtsBreadCrumbs>
      
          <div className={classes.grow} style={{ textAlign: "center" }}>
            {updating ? "Updating.." : null}
          </div>

          <Tooltip
            disableFocusListener
            arrow="true"
            TransitionComponent={Zoom}
            title="Preview lesson"
          >
            <Fab
              color="primary"
              aria-label="add"
              style={{
                marginRight: "15px",
                width: "35px",
                height: "30px",
                float: "right",
                backgroundColor: "#4CAF50",
              }}
              onClick={previewPage}
              className={classes.fabButton}
            >
              <VisibilityIcon />
            </Fab>
          </Tooltip>
          <Button variant="outlined" color="inherit" onClick={saveContent}>
            Update
          </Button>
        </Toolbar>
      </AppBar>

      {!openPreview ? (
        <div>
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  id="lines"
                  label="Lines Per Entry "
                  name="lines"
                  value={lines}
                  fullWidth
                  style={{
                    display: "block",
                  }}
                  onChange={(e) => setLines(e.target.value)}
                  autoFocus
                />
              </Grid>               
            </Grid>
            <Typography
              style={{ textAlign: "left", color: "black", marginTop: "15px" }}
            >
              Content
            </Typography>
            <Grid container spacing={1}>
              <AssignmentColumn
                updateCellCallback={updateAssignment}
                updateColTitleCallback={updateColTitle}
                deleteRowCallback={deleteRow}
                deleteColCallback={deleteColumn}
                content={content}
              ></AssignmentColumn>
            </Grid>
          </form>
        </div>
      ) : (
        <Grid container style={{ width: "99%", margin: "0 auto" }} spacing={1}>
          <Grid item xs={12}>
            <Reader
              preview={true}
              moduleCid={modcid}
              courseId={courseid}
              //   contentId={parentcid}
              closePreview={closePreviewPage}
            ></Reader>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
