import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import {
  createMuiTheme,
  MuiThemeProvider,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import AuthService from "../../utils/AuthService";
import axios from "axios";
import Menu from "@material-ui/core/Menu";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import { useParams } from "react-router-dom";
import FlipToFrontSharpIcon from "@material-ui/icons/FlipToFrontSharp";
import DeleteSharpIcon from "@material-ui/icons/DeleteSharp";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { Markup } from "interweave";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Box from "@material-ui/core/Box";
import ListItemText from "@material-ui/core/ListItemText";
import Zoom from "@material-ui/core/Zoom";
import MultipleChoiceEditor from "./MultipleChoiceEditor";
import IraabEditor from "./IraabEditor";
import { Redirect } from "react-router-dom";
import AtsBreadCrumbs from "../AtsBreadCrumbs";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  fabButton: {
    margin: "0 auto",
    width: "40px",
    height: "40px",
  },
}));

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MuiToolbar: {
        root: {
          backgroundColor: "#ffeb3b",
        },
      },
      MuiTableCellHead: {
        root: {
          backgroundColor: "#FF0000",
        },
      },
      MuiTableFooter: {
        root: {
          backgroundColor: "#ffeb3b",
        },
      },
    },
  });

export default function ItemList() {
  console.log("itemlist");
  const classes = useStyles();
  let { bankid } = useParams();
  const [openUpdateEditor, setOpenUpdateEditor] = useState(false);
  const [itemTypeToEdit, setItemTypeToEdit] = useState();
  const [bankname, setBankname] = useState();
  const [items, setItems] = useState(null);
  const [selectedItems, setSelectedItems] = useState(null);
  const [openMoveItems, setOpenMoveItems] = useState(false);
  const [itembanks, setItembanks] = useState(null);
  const [itemBankIdToMove, setItemBankIdToMove] = useState();
  const [itemIdToEdit, setItemIdToEdit] = useState();

  const columns = [
    {
      name: "Id",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography
              variant="overline"
              onClick={() => openEditItemEditor(value, tableMeta.rowData[2])}
              style={{ cursor: "pointer", color: "blue" }}
            >
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "Name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="body2" gutterBottom>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "ItemType",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography variant="overline" gutterBottom>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "Title",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Markup content={value} />;
        },
      },
    },
  ];

  const handleBankSel = (event) => {
    setItemBankIdToMove(event.target.value);
  };
  const fetchItemsInBank = async () => {
    const resp = await axios(
      `${AuthService.API_BASE_URL}api/list/itemsinbank/id/${bankid}`,
      AuthService.getAuthHeader()
    );
    setBankname(resp.data[0].BankName);
    if (typeof resp.data[0].Items !== "undefined") {
      setItems([]);
    } else {
      setItems(resp.data);
    }
  };

  const fetchItemBanks = async () => {
    const resp = await axios(
      `${AuthService.API_BASE_URL}api/list/itembanks`,
      AuthService.getAuthHeader()
    );
    setItembanks(resp.data);
  };

  const updateItemBank = async (obj) => {
    await axios.post(
      `${AuthService.API_BASE_URL}api/item/moveitems`,
      obj,
      AuthService.getAuthHeader()
    );
    setOpenMoveItems(false);
    fetchItemsInBank();
  };

  function handleMove(selectedRows, displayData, setSelectedRows) {
    var selItems = selectedRows.data.map(function (v, i) {
      return displayData[v.dataIndex];
    });
    setSelectedItems(selItems);
    fetchItemBanks();
    setOpenMoveItems(true);
  }

  const handleClose = () => {
    setOpenMoveItems(false);
  };

  const handleUpdateItemBank = () => {
    var ids = selectedItems.map(function (v, i) {
      return v.data[0];
    });
    var updtObj = {};
    updtObj.bankid = itemBankIdToMove;
    updtObj.itemids = ids.join(",");
    updateItemBank(updtObj);
  };

  function handleDelete(selectedRows, displayData, setSelectedRows) {
    console.log(selectedRows);
    console.log(displayData);
    console.log(setSelectedRows);
  }

  function openEditItemEditor(itemid, itemType) {
    setItemIdToEdit(itemid);
    setItemTypeToEdit(itemType);
    setOpenUpdateEditor(true);
  }

  function closeItemUpdateEditor(updated) {
    setOpenUpdateEditor(false);
    if (updated) {
      fetchItemsInBank();
    }
  }

  function ItemEditor({ id, itemtype, bankid, closeCallback }) {
    console.log("ItemEditor", bankid, itemtype);
    if (itemtype === "Markup" || itemtype === "MCMA" || itemtype === "MFB" ||
      itemtype === "Iraab" || itemtype === "Ranking" || itemtype === "ReArrange" || itemtype === "Match") {
      var url = `/Authoring/itemtype/${itemtype.toLowerCase()}/bank/${bankid}`;
      if (id) {
        url = `/Authoring/itemtype/${itemtype.toLowerCase()}/bank/${bankid}/item/${id}`;
      }
      return <Redirect to={url} />;
    }

    return (
      <>
        <MultipleChoiceEditor
          newItemType={itemtype}
          bankid={bankid}
          id={id}
          bankname={bankname}
          closeCallback={closeCallback}
        ></MultipleChoiceEditor>
      </>
    );


  }

  function CustomToolbar({ selectedRows, displayData, setSelectedRows }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openeditor, setOpeneditor] = useState(false);
    const [editItemType, setAuthorItemType] = React.useState(null);

    const StyledMenu = withStyles({
      paper: {
        border: "1px solid #d3d4d5",
      },
    })((props) => (
      <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        {...props}
      />
    ));

    const StyledMenuItem = withStyles((theme) => ({
      root: {
        "&:focus": {
          backgroundColor: theme.palette.primary.main,
          "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
            color: theme.palette.common.white,
          },
        },
      },
    }))(MenuItem);

    const itemTypes = [
      "Cancel",
      "MCMA",
      "Iraab",
      "Ranking",
      "MFB",
      "ReArrange",
      "Match",
      "Markup",
    ];

    const openNewItemMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };

    function openNewItemEditor(option) {
      if (option === "Cancel") {
        setAnchorEl(null);
        return;
      }
      setAuthorItemType(option);
      setAnchorEl(null);
      setOpeneditor(true);
    }
    function closeNewItemEditor(updated) {
      setOpeneditor(false);
      if (updated) {
        fetchItemsInBank();
      }
    }
    function handleonClickAwayClose() {
      setAnchorEl(null)
    }
    return (
      <div style={{ float: "right" }}>
        <ClickAwayListener onClickAway={handleonClickAwayClose}>
          <Tooltip
            disableFocusListener
            arrow="true"
            TransitionComponent={Zoom}
            title="Create new item"
          >
            <Fab
              color="secondary"
              aria-label="add"
              onClick={openNewItemMenu}
              className={classes.fabButton}
            >
              <AddIcon fontSize="small" />
            </Fab>
          </Tooltip>
        </ClickAwayListener>
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
        >
          {itemTypes.map(function (option, i) {
            return (
              <StyledMenuItem onClick={() => openNewItemEditor(option)}>
                <ListItemText primary={option} />
              </StyledMenuItem>
            );
          })}
        </StyledMenu>
        {openeditor ? (
          <ItemEditor
            itemtype={editItemType}
            bankid={bankid}
            id={null}
            closeCallback={closeNewItemEditor}
          ></ItemEditor>
        ) : null}
      </div>
    );
  }

  function CustomToolbarSelect({ selectedRows, displayData, setSelectedRows }) {
    return (
      <div style={{ float: "right" }}>
        <IconButton>
          <FlipToFrontSharpIcon
            onClick={() =>
              handleMove(selectedRows, displayData, setSelectedRows)
            }
          />
        </IconButton>
        <IconButton>
          <DeleteSharpIcon
            onClick={() =>
              handleDelete(selectedRows, displayData, setSelectedRows)
            }
          />
        </IconButton>
      </div>
    );
  }

  const options = {
    filterType: "dropdown",
    responsive: "scroll",
    print: false,
    download: false,
    width: "98%",
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <CustomToolbarSelect
        selectedRows={selectedRows}
        displayData={displayData}
        setSelectedRows={setSelectedRows}
      />
    ),
    customToolbar: (selectedRows, displayData, setSelectedRows) => (
      <CustomToolbar
        selectedRows={selectedRows}
        displayData={displayData}
        setSelectedRows={setSelectedRows}
      />
    ),
    fixedHeaderOptions: { xAxis: false, yAxis: true },
  };
  /*  function ItemBanks() {
    if (itembanks === null) {
      return null;
    }
    return itembanks.map(function (v, i) {
      return <MenuItem value={v.Id}> {v.Name.trim()}</MenuItem>;
    });
  }
  function setItemBankId(v) {
    console.log(v);
    setItemBankIdToMove(v.target.value);
  } */

  useEffect(() => {
    if (AuthService.isLoggedIn()) {
      fetchItemsInBank();
    }
  }, [bankid]);

  if (!items) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="center"
        p={1}
        m={1}
        bgcolor="background.paper"
      >
        <Typography>Loading items...</Typography>
        <LinearProgress color="secondary" />
      </Box>
    );
  }
  const breadcrumbs = [{ title: "Item Banks", link: "/Authoring/itembanks", }];

  return (
    <>
      <AtsBreadCrumbs last={bankname} crumbs={breadcrumbs} ></AtsBreadCrumbs>
      <MuiThemeProvider theme={getMuiTheme()}>
        <div style={{ padding: "25px" }}>
          <MUIDataTable
            title={bankname + "-" + items.length}
            data={items}
            columns={columns}
            options={options}
          />
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={openMoveItems}
            onClose={handleClose}
          >
            <DialogTitle>Select Target Item Bank</DialogTitle>
            <DialogContent>
              <form className={classes.container}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-dialog-select-label">
                    Item Bank
                  </InputLabel>
                  <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    value={itemBankIdToMove}
                    onChange={handleBankSel}
                    input={<Input />}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {itembanks
                      ? itembanks.map(function (v, i) {
                        return (
                          <MenuItem value={v.Id}> {v.Name.trim()}</MenuItem>
                        );
                      })
                      : null}
                  </Select>
                </FormControl>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleUpdateItemBank} color="primary">
                Ok
              </Button>
            </DialogActions>
          </Dialog>

          {openUpdateEditor ? (
            <ItemEditor
              id={itemIdToEdit}
              bankid={bankid}
              itemtype={itemTypeToEdit}
              closeCallback={closeItemUpdateEditor}
            ></ItemEditor>
          ) : null}
        </div>
      </MuiThemeProvider>
    </>
  );
}
