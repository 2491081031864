import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import AuthService from "../../utils/AuthService";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Grid from "@material-ui/core/Grid";
import Reader from "../Reader";
import ApiMap from "../../utils/ApiMap";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  appBar: {
    position: "relative",
    color: "#F5F5F5",
    height: "60px",
    backgroundColor: "#45a0cb",
  },
  grow: { flexGrow: 1 },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    textAlign: "center",
  },
  form: {
    padding: "5px",
    margin: "5px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



function PageList({curpg, pagecount, handlePgSelCallback}) {
  let cntrls = [];
  for(var i = 0; i < pagecount; i++) {
    cntrls.push(<MenuItem value={i+1}>Page {i+1}</MenuItem>)
  }
  cntrls.push(<MenuItem value="-1">Add Page</MenuItem>);

  // value={itemBankIdToMove}
  // onChange={handleBankSel}
  return  <Select labelId="pagenum" onChange={handlePgSelCallback} id="pagenum" value={curpg}>{cntrls}</Select>
}




export default function PageEditor() {
  let { id, courseid, chapid, modid } = useParams();
  const classes = useStyles();
  const [content, setContent] = React.useState("");
  const [name, setName] = React.useState("");
  const [curPgNum, setCurPgNum] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(null);
  const [title, setTitle] = React.useState("");
  const [dbId, setDbId] = React.useState(id);
  const [updated, setUpdated] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);

  const [openConfirm, setOpenConfirm] = React.useState(false);
  
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const fetchLesson = async () => {
    const resp = await axios(
      `${ApiMap.CONTENT}/coursecontent/${courseid}/module/${modid}/chapter/${chapid}/lesson/${id}?pgnum=${curPgNum}`,
      AuthService.getAuthHeader()
    );
    setTotalPage(resp.data.TotalPages);
    setCurPgNum(resp.data.CurPgNum);
    setContent(resp.data.Value);
    setTitle(resp.data.Title);
    setName(resp.data.Name);
  };

  useEffect(() => {
      fetchLesson();
  }, [curPgNum]);

  const pageSelCallback = (event) => {
    let pgNum = event.target.value;
    if (pgNum == -1) {
      setOpenConfirm(true);
    } else {
      setCurPgNum(pgNum);
    }
  }
  
  const closePreviewPage = () => {
    setOpenPreview(false);
  };
  const previewPage = () => {
    setOpenPreview(!openPreview);
  };
  const saveContent = (e) => {
    e.preventDefault();
    const contentObj = {
      Name: name,
      Title: title,
      Value: content,
      CourseId: courseid,
      ModuleId:modid,
      ChapterId:chapid
    };

    if (dbId) {
      contentObj.Id = dbId;
      var url = `${ApiMap.CONTENT}/coursecontent/${courseid}/module/${modid}/chapter/${chapid}/lesson/${id}?pgnum=${curPgNum}`;
      setUpdating(true);
      async function update() {
        try {
          const response = await axios.post(
            url,
            contentObj,
            AuthService.getAuthHeader()
          );
          if (response.status === 200) {
            setUpdating(false);
            setUpdated(true);
          }
        } catch (err) {
          console.log(err);
        }
      }
      update();
    } else {
      setUpdating(true);
      async function create() {
        try {
          const response = await axios.put(
            `${AuthService.API_BASE_URL}api/item/pkgcontent`,
            contentObj,
            AuthService.getAuthHeader()
          );
          if (response.status === 200) {
            setUpdated(true);
            setDbId(response.data.id);
            addchildcid(response.data.id);
          }
        } catch (err) {
          console.log(err);
        }
      }
      async function addchildcid(childcid) {
        try {
          const response = await axios.put(
            `${AuthService.API_BASE_URL}api/item/addchildcid`,
            { contentid: courseid, newchildid: childcid },
            AuthService.getAuthHeader()
          );
          setUpdating(false);
          console.log(response);
        } catch (err) {
          console.log(err);
        }
      }
      create();
    }
  };



  return (
    <div className={classes.root}>
      <Dialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Add a Page"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to add a page?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirm} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCloseConfirm} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
        <AppBar
          position="fixed"
          className={classes.appBar}
          style={{ boxShadow: "none" }}
        >
          <Toolbar>
          
            {dbId ? (
              <Typography variant="h6" className={classes.title}>
                {dbId + " " + name}
              </Typography>
            ) : (
              <Typography variant="h6" className={classes.title}>
                {name}
              </Typography>
            )}
            <div className={classes.grow} style={{ textAlign: "center" }}>
              <FormControl className={classes.formControl}>
                   <PageList handlePgSelCallback={pageSelCallback} curpg={curPgNum} pagecount={totalPage}></PageList>
                </FormControl>
            </div>

           <div><span style={{marginRight:"15px"}}>{updating ? "Updating.." : null}</span> </div>
            <Button
              autoFocus
              variant="contained"
              color="primary"
              onClick={saveContent}
            >
              {dbId ? "Update" : "Create"}
            </Button>
            

                <Tooltip
              disableFocusListener
              arrow="true"
              TransitionComponent={Zoom}
              title="Preview lesson"
            >
              <Fab
                color="primary"
                aria-label="add"
                style={{
                  marginRight: "15px",
                  marginLeft: "15px",
                  width: "35px",
                  height: "30px",
                  float: "right",
                }}
                onClick={previewPage}
                className={classes.fabButton}
              >
                <VisibilityIcon />
              </Fab>
            </Tooltip>
          </Toolbar>
        </AppBar>

        {!openPreview ? (
          <div>
            <form className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    id="name"
                    label="Name"
                    name="Name"
                    value={name}
                    fullWidth
                    style={{
                      display: "block",
                    }}
                    onChange={(e) => setName(e.target.value)}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    id="title"
                    label="Title"
                    name="Title"
                    value={title}
                    fullWidth
                    style={{
                      display: "block",
                    }}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Grid>                
              </Grid>
              <div style={{textAlign:"left"}}>
              <Typography variant="h6">
                Content
              </Typography>
              </div>
              <Editor
                apiKey="plgjmz2q4q6gougxbjnuycs8nmkg42fni1sne8bgj57ianzu"
                value={content}
                init={{
                  plugins:
                    "print preview paste importcss searchreplace autolink  directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons",
                  menubar: "edit view insert format tools table help",
                  toolbar:
                    "undo redo | bold italic underline strikethrough | ltr rtl| formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview | insertfile image media template link anchor codesample ",
                  toolbar_sticky: false,
                  quickbars_insert_toolbar: false,
                  quickbars_image_toolbar: false,
                  font_formats:
                    "KFGQPC Uthmanic Script HAFS;Traditional Arabic;Arial; Comic Sans MS; Georgia;Helvetica; Impact;Tahoma; Times New Roman; Verdana; Trebuchet MS; Courier New",
                  quickbars_selection_toolbar:
                    "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                  block_formats:
                    "Div=div; Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3;  Header 4=h4;  Header 5=h5;  Header 6=h6; Preformatted=pre",
                  autosave_ask_before_unload: true,
                  image_advtab: true,
                  content_css: "//www.tiny.cloud/css/codepen.min.css",
                  importcss_append: true,
                  height: 800,
                  forced_root_block: "div",
                  templates: [
                    {
                      title: "Audio",
                      description: "Audio content",
                      content:
                        '<div style="max-width: 400px;" data-ephox-embed-iri="https://askquran-media.s3-us-west-2.amazonaws.com/audio/ayah/khalifatnj/011118.mp3"><audio style="width: 100%;" controls="controls"> <source src="https://askquran-media.s3-us-west-2.amazonaws.com/audio/ayah/khalifatnj/011118.mp3" type="audio/mpeg"> Your browser does not support the audio element. </audio></div>',
                    },
                    {
                      title: "Video",
                      description: "watch the video",
                      content:
                        '<div  data-ephox-embed-iri="https://atscontent.askquran.com/modules/23/kaana-wa-akhawaatuha-mobile.mp4"><video style="width: 400px;height:300px" controls="controls"> <source src="https://atscontent.askquran.com/modules/23/kaana-wa-akhawaatuha-mobile.mp4" type="video/mp4">Your browser does not support the video element.</video></div>',
                    },
                    {
                      title: "New Table",
                      description: "creates a new table",
                      content:
                        '<div class="mceTmpl"><table width="80%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
                    },
                    {
                      title: "Starting my story",
                      description: "A cure for writers block",
                      content: "Once upon a time...",
                    },
                    {
                      title: "New list with dates",
                      description: "New List with dates",
                      content:
                        '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
                    },
                  ],
                  template_cdate_format:
                    "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
                  template_mdate_format:
                    "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",

                  image_caption: true,
                  toolbar_mode: "sliding",
                }}
                onEditorChange={(newContent) => setContent(newContent)}
                style={{ padding: "8px" }}
              />
            </form>
          </div>
        ) : (
          <Grid
            container
            style={{ width: "99%", margin: "0 auto" }}
            spacing={1}
          >
            <Grid item xs={12}>
              <Reader
                preview={true}
                moduleId={modid}
                courseId={courseid}
                chapterId={chapid}
                contentId={id}
                closePreview={closePreviewPage}
              ></Reader>
            </Grid>
          </Grid>
        )}
      
    </div>
  );
}
