export function DefaultItemVal(itemType) {
  return {
    Iraab: {
      Rationale: {
        ContentId: "572666710",
      },
      Iraab: {
        id: 1,
        Rationale: "No. check again",
        Value:
          "كانَ الجوُّ صحوًا وفجأةً اشْتدت الريحُ وصارَ الجوُّ غائمًا وأمسى المطرُ مُنْهَمِرًا وباتَ الجوُّ باردًا وفي اليومِ التالي أصبحَت الشمسُ مُشْرِقَةً فخرجَ الراعي بِغَنَمِهِ مسرورًا فقد ظهرَ العُشْبُ الأخْضَرُ على الجبلِ وأضحت الغنمُ متفرقةً تَبحثُ عن العُشْبِ وتجري هنا وهناكَ وظلَّ الجوُّ صحوًا",
      },
    },
    MFB: {
      Rationale: {
        ContentId: "572666710",
      },
      Options: [
        {
          Value:"هذه/هذا مدرسة وهي/وهي/وهي/وهي كبيرة",
          id:1,
          Rationale:"",
        }
      ]
    },
    Ranking: {
      Rationale: {
        ContentId: "572666710",
      },
      Options: [
        {
          id: 1,
          Rationale: "",
          Value:
            "قالَ الكَلْبُ الكَبِيرُ لِلْكَلبِ الصَّغيرِ : لاَ تُقَطَّعِ الزَّرْع... أَنَا قَطَّعْتُهُ فَرَبَطَنِى صَاحِبِى!",
        },
        {
          id: 2,
          Rationale: "",
          Value:
            "ضَحِكَ الصَّغيرُ وَرَدَّ قَائِلاً  أَعْرِفُ سببَ كَلامِك!لأَنَّكَ مربوطٌ! لاَ تحبُّ أن ألْعَبَ أنا كما أريدُ!",
        },
        {
          id: 3,
          Rationale: "",
          Value: "وخرجَ إلى الشارع ... نَبَحَ وخوَّف البَنَاتَ وعَضَّ الأولادَ",
        },
        {
          id: 4,
          Rationale: "",
          Value: "أَحْضَرَ صاحِبه الطَّوْقَ... رَبَطَه...جَنْبَ الْمَرْبُوط!",
        },
      ],
    },
    Markup: {
      Rationale: {
        ContentId: "572666710",
      },
      Text: "This is paragraph full of different kinds of words",
      Direction: "RTL",
      MarkupType: "Underline",
      Options: {
        "1": [
          {
            Type: "noun",
            Rationale: {
              ContentId: "572666710",
            },
          },
          {
            Type: "noun",
            Rationale: {
              ContentId: "572666710",
            },
          },
        ],
        "2": [
          {
            Type: "noun",
            Rationale: {
              ContentId: "572666710",
            },
          },
        ],
        "3": [
          {
            Type: "noun",
            Rationale: {
              ContentId: "572666710",
            },
          },
        ],
      },
    },
    ReArrange: {
      Rationale: {
        ContentId: "572666710",
      },
      Options: [
        {
          id: 1,
          Rationale: "",
          Value: "ذهب الولد الى المدرسة مع أمه",
        },
        {
          id: 2,
          Rationale: "",
          Value: "الولد ذهب الى المدرسة مع أمه",
        },
        {
          id: 3,
          Rationale: "",
          Value: "الولد ذهب مع أمه الى المدرسة",
        },
        {
          id: 4,
          Rationale: "",
          Value: "ذهب الولد مع أمه الى المدرسة",
        },
      ],
    },
    Discussion: ["Discussion"],
    Match: {
      Rationale: {
        ContentId: "572666710",
      },
      Options: [
        {
          id: 1,
          Name: "option1",
          Value: "A",
        },
        {
          id: 2,
          Name: "option2",
          Value: "B",
        },
        {
          id: 3,
          Name: "option3",
          Value: "C",
        },
        {
          id: 4,
          Name: "option4",
          Value: "D",
        },
      ],
      Distractors: [
        {
          Value: "E",
          Rationale: "",
        },
        {
          Value: "F",
          Rationale: "",
        },
      ],
    },
    MCMA: {
      Rationale: {
        ContentId: "572665991",
      },
      Options: [
        {
          IsCorrect: false,
          Value: "المُفْرَد المُذَكَّر",
          Rationale: "",
          id: 1,
        },
        {
          IsCorrect: false,
          Value: "المُفْرَد المُؤَنَّث",
          Rationale: "",
          id: 2,
        },
        {
          IsCorrect: true,
          Value: "جَمْعُ المُذَكَّر",
          Rationale: "",
          id: 3,
        },
        {
          IsCorrect: true,
          Value: "جَمْعُ المُؤَنَّث",
          Rationale: "",
          id: 4,
        },
        {
          IsCorrect: false,
          Value: "جَمْعُ غير العَاقِل",
          Rationale: "",
          id: 5,
        },
      ],
    },
  }[itemType];
}
