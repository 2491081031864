import React, { useState, useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import AuthService from "../../utils/AuthService";
import { faSpinner, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CRC32 } from "../../utils/Utils";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import ApiMap from "../../utils/ApiMap";


const AutoSaveProgress = ({
  aid,
  crc,
  contentId,
  courseId,
  chapterId,
  moduleId,
  getUserAssignmentCallback,
  createUserAssignmentCallback,
}) => {
  const [autosave, setAutoSave] = useState(false);
  const [readyToAutoSave, setReadyToAutoSave] = useState(false);
  const AUTOSAVE_INTERVAL = 5000;
  const lastCrc32 = useRef();
  if (!lastCrc32.current) {
    lastCrc32.current = crc;
  }

  const updateUserAssignment = (assignmentObj) => {
    console.log("assignmentObj:", assignmentObj);
    var url = `${ApiMap.UPDT_ASSIGNMENT}/userassignment/${courseId}/module/${moduleId}/chapter/${chapterId}/lesson/${contentId}`;
    setAutoSave(true);
    async function update() {
      try {
        const response = await axios.post(
          url,
          assignmentObj,
          AuthService.getAuthHeader()
        );
        if (response.status === 200) {
          setAutoSave(false);
        }
      } catch (err) {
        setAutoSave(false);
        console.log(err);
      }
    }
    update();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      var assignmentObj = getUserAssignmentCallback();
      var newcrc = CRC32(JSON.stringify(assignmentObj));
      if (newcrc === lastCrc32.current) {
        return;
      }
      setReadyToAutoSave(true);
      if (assignmentObj.Id) {
        lastCrc32.current = newcrc;
        updateUserAssignment(assignmentObj);
      } else {
        createUserAssignmentCallback();
      }
    }, AUTOSAVE_INTERVAL);
    return () => clearInterval(interval);
  });

  function submitAssignment(e) {
    if (e) {
      e.preventDefault();
    }
    var assignmentObj = getUserAssignmentCallback();
    if (assignmentObj) {
      updateUserAssignment(assignmentObj);
    } else {
      createUserAssignmentCallback();
    }
  }

  return (
    <Grid container spacing={0.1}>
      <Grid
        key={"gridmsg_" + aid}
        style={{ textAlign: "right", paddingRight: "30px" }}
        alignItems="right"
        justify="space-evenly"
        item
        xs={8}
      >
        <div
          key={"msg_" + aid}
          style={{
            textAlign: "left",
            paddingLeft: "25px",
            paddingRight: "25px",
          }}
        >
          {readyToAutoSave ? (
            autosave ? (
              <Typography style={{ color: "blue" }} variant="overline">
                <FontAwesomeIcon icon={faSpinner} spin /> saving...
              </Typography>
            ) : (
              <Fade in={true} timeout={3000}>
                <Typography style={{ color: "green" }} variant="overline">
                  <FontAwesomeIcon icon={faCheckCircle} /> saved
                </Typography>
              </Fade>
            )
          ) : null}
        </div>
      </Grid>
      <Grid
        style={{ textAlign: "right", paddingRight: "30px" }}
        alignItems="right"
        justify="space-evenly"
        item
        xs={4}
      >
        <Button
          onClick={submitAssignment}
          variant="contained"
          color="secondary"
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

export default AutoSaveProgress;
