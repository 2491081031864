import React, { useState, useEffect, useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import axios from "axios";
import AuthService from "../../utils/AuthService";
import { Editor } from "@tinymce/tinymce-react";
import AtsBreadCrumbs from "../AtsBreadCrumbs";
import { useParams } from "react-router-dom";
import MCMAOptions from "./MultipleChoiceOptions";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "transparent",
  },
  grow: { flexGrow: 1 },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    textAlign: "center",
  },
  form: {
    padding: "7px",
    margin: "7px",
  },
  wordtype: {
    fontSize: "x-large",
    direction: "rtl",
    borderBottom: "1px dashed #E1E1E1",
    width: "100%",
    textAlign: "right",
    color: "#0000ff",
  },
  stemtext: {
    fontSize: "x-large",
    textAlign: "right",
    direction: "rtl",
    backgroundColor: "#EFEFEF",
    border: "1.5px dashed #555",
    padding: "15px",
    lineHeight: "2.5rem",
  },
}));

const defaultVals = {
  Rationale: {
    ContentId: "572666710",
  },
  Text:
    "وَالْأَرْضَ مَدَدْنَاهَا وَأَلْقَيْنَا فِيهَا رَوَاسِيَ وَأَنبَتْنَا فِيهَا مِن كُلِّ زَوْجٍ بَهِيجٍ",
  Direction: "RTL",
  MarkupType: "Underline",
  Options: [],
};

const AssignmentTextField = withStyles({
  root: {
    "& .MuiInput-underline:before": {
      borderBottom: "1px dashed #888",
    },
    "& .MuiInput-underline:hover::before": {
      borderBottom: `1px dashed darkorange`,
    },
    "& .MuiInput-underline::after": {
      borderBottom: `0px solid #ff6e40`,
    },
  },
})(TextField);

function WordTypeTextField({ value, wkey, col, openDlgCallback }) {
  const classes = useStyles();
  function showDlg() {
    openDlgCallback(wkey, col);
  }

  return (
    <AssignmentTextField
      InputProps={{
        classes: {
          input: classes.wordtype,
        },
      }}
      style={{ width: "100%" }}
      onClick={showDlg}
      defaultValue={value}
    ></AssignmentTextField>
  );
}

export default function McmaEditor() {
  console.log("Mcmaeditor");
  let { itemid, bankid } = useParams();
  console.log("Start", bankid, itemid);
  const classes = useStyles();

  const [content, setContent] = React.useState();
  const [stemText, setStemText] = React.useState();
  const [options, setOptions] = React.useState();
  const [name, setName] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [itemType, setItemType] = useState("MCMA");
  const [dbId, setDbId] = React.useState(itemid);
  const tempOptions = useRef(null);

  function setDefaultValues() {
    setContent(defaultVals);
    setStemText(defaultVals.Text);
    setOptions(defaultVals.Options);
    tempOptions.current = defaultVals.Options;
  }

  useEffect(() => {
    var url = `${AuthService.API_BASE_URL}api/item/contentbyid/id/${dbId}`;
    const fetchData = async () => {
      const resp = await axios(url, AuthService.getAuthHeader());
      console.log(resp);
      var item = JSON.parse(resp.data.Value);
      setStemText(item.Text);
      setOptions(item.Options);
      tempOptions.current = item.Options;
      setContent(item);
      setItemType(resp.data.ItemType);
      setTitle(resp.data.Title);
      setName(resp.data.Name);

    };
    if (dbId) {
      fetchData();
    } else {
      setDefaultValues();
    }
  }, [dbId]);

  const createContent = (e) => {
    e.preventDefault();
    var newContent = content;
    newContent.Text = stemText;
    newContent.Options = tempOptions.current;
    const contentObj = {
      Name: name,
      Title: title,
      Value: content,
      ItemType: itemType,
      ContentType: "Item",
      Duration: 0,
      DisplayRank: 0,
      MediaUrl: null,
      ContentFormat: "item",
      IsPackage: 0,
    };
    var url = `${AuthService.API_BASE_URL}api/item/pkgcontent`;
    async function createItem() {
      try {
        const response = await axios.put(
          url,
          contentObj,
          AuthService.getAuthHeader()
        );
        if (response.status === 200) {
          setDbId(response.data.id);
          addItemToBank(response.data.id, bankid);
        }
      } catch (err) {
        console.log(err);
      }
    }
    async function addItemToBank(itemId, bankId) {
      url = `${AuthService.API_BASE_URL}api/item/additemtobank`;
      try {
        await axios.put(
          url,
          { bankid: bankId, itemid: itemId },
          AuthService.getAuthHeader()
        );
      } catch (err) {
        console.log(err);
      }
    }
    createItem();
  };

  const updateContent = (e) => {
    e.preventDefault();
    var newContent = content;
    newContent.Text = stemText;
    newContent.Options = tempOptions.current;
    const contentObj = {
      Name: name,
      Title: title,
      Value: newContent,
      ItemType: itemType,
      ContentType: "Item",
      Duration: 0,
      DisplayRank: 0,
      MediaUrl: null,
      ContentFormat: "item",
      IsPackage: 0,
    };

    contentObj.Id = dbId;
    var url = `${AuthService.API_BASE_URL}api/item/updatecontent`;
    async function updateItem() {
      try {
        await axios.post(url, contentObj, AuthService.getAuthHeader());
      } catch (err) {
        console.log(err);
      }
    }
    updateItem();
  };

  function updateOptions(options, updateState) {
    tempOptions.current = options;
    if (updateState) {
      let newArr = [...tempOptions.current];
      setOptions(newArr);
    }
  }

  if (!content) {
    return <div>Loading</div>;
  }
  const breadcrumbs = [
    { title: "Item Banks", link: "/Authoring/itembanks" },
    { title: "Bank", link: `/Authoring/itembank/${bankid}` },
  ];
  var lastBreadcrumb = name;
  if (dbId) {
    lastBreadcrumb = name + "(" + dbId + ")";
  }
  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={classes.appBar}
        style={{ boxShadow: "none" }}
      >
        <Toolbar>
          <AtsBreadCrumbs
            last={lastBreadcrumb}
            crumbs={breadcrumbs}
          ></AtsBreadCrumbs>
          <div className={classes.grow}></div>
          <Button
            autoFocus
            variant="contained"
            color="primary"
            style={{ marginLeft: "15px", marginRight: "15px" }}
            onClick={createContent}
          >
            {"Create"}
          </Button>
          {dbId ? (
            <Button
              autoFocus
              variant="contained"
              color="primary"
              onClick={updateContent}
            >
              {"Update"}
            </Button>
          ) : null}
        </Toolbar>
      </AppBar>
      <form className={classes.form} noValidate>
        {/* {dbId ? (
          <FormLabel>{bankname + " / " + dbId + " - " + itemType}</FormLabel>
        ) : (
          <FormLabel>{bankname + " / " + itemType}</FormLabel>
        )} */}
        <TextField
          variant="outlined"
          margin="normal"
          required
          id="name"
          label="Name"
          name="Name"
          value={name}
          fullWidth
          style={{
            maxWidth: "400px",
            display: "block",
          }}
          onChange={(e) => setName(e.target.value)}
          autoFocus
        />
        <Typography style={{ color: "black" }}>Stem</Typography>
        <Editor
          apiKey="plgjmz2q4q6gougxbjnuycs8nmkg42fni1sne8bgj57ianzu"
          value={title}
          init={{
            plugins:
              "print preview paste importcss searchreplace autolink  directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons",
            menubar: "edit view insert format tools table help",
            toolbar:
              "undo redo | bold italic underline strikethrough | ltr rtl| formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview | insertfile image media template link anchor codesample ",
            toolbar_sticky: false,
            quickbars_insert_toolbar: false,
            quickbars_image_toolbar: false,
            quickbars_selection_toolbar:
              "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
            block_formats:
              "Div=div; Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3;  Header 4=h4;  Header 5=h5;  Header 6=h6; Preformatted=pre",
            autosave_ask_before_unload: true,
            image_advtab: true,
            content_css: "//www.tiny.cloud/css/codepen.min.css",
            importcss_append: true,
            height: 250,
            forced_root_block: "div",
            templates: [
              {
                title: "Audio",
                description: "Audio content",
                content:
                  '<div style="max-width: 650px;" data-ephox-embed-iri="https://askquran-media.s3-us-west-2.amazonaws.com/audio/ayah/khalifatnj/011118.mp3"><audio style="width: 100%;" controls="controls"> <source src="https://askquran-media.s3-us-west-2.amazonaws.com/audio/ayah/khalifatnj/011118.mp3" type="audio/mpeg"> Your browser does not support the audio element. </audio></div>',
              },
              {
                title: "Video",
                description: "watch the video",
                content:
                  '<div  data-ephox-embed-iri="https://atscontent.askquran.com/modules/23/kaana-wa-akhawaatuha-mobile.mp4"><video style="width: 100%;height:300px" controls="controls"> <source src="https://atscontent.askquran.com/modules/23/kaana-wa-akhawaatuha-mobile.mp4" type="video/mp4">Your browser does not support the video element.</video></div>',
              },
              {
                title: "New Table",
                description: "creates a new table",
                content:
                  '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
              },
              {
                title: "Starting my story",
                description: "A cure for writers block",
                content: "Once upon a time...",
              },
              {
                title: "New list with dates",
                description: "New List with dates",
                content:
                  '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
              },
            ],
            template_cdate_format:
              "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
            template_mdate_format:
              "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
            image_caption: true,
            toolbar_mode: "sliding",
          }}
          onEditorChange={(t) => setTitle(t)}
          style={{ padding: "8px", marginBottom: "15px" }}
        />
        <Typography style={{ color: "black", marginTop: "15px" }}>
          Choices
        </Typography>
        <MCMAOptions
          updateCallback={updateOptions}
          options={options}
        ></MCMAOptions>
      </form>
    </div>
  );
}
