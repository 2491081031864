import axios from "axios";
import AuthService from "../utils/AuthService";
import ApiMap from "../utils/ApiMap";

class CourseServices {
  async markLessonComplete(crsId, modId, chpId, lsnId) {
    const resp = await axios.post(
      `${ApiMap.MARK_LSN_COMPLETE}/${crsId}/module/${modId}/chapter/${chpId}/lesson/${lsnId}`,
      null,
      AuthService.getAuthHeader()
    );
    return resp;
  }
  async markLessonInComplete(crsId, modId, chpId, lsnId) {
    const resp = await axios.post(
      `${ApiMap.MARK_LSN_INCOMPLETE}/${crsId}/module/${modId}/chapter/${chpId}/lesson/${lsnId}`,
      null,
      AuthService.getAuthHeader()
    );
    return resp;
  }
  async getCourseGrades(crsId) {
    let postdata = {
      Name: "GetCourseGrades",
      IndexName: "index2",
      Key: "grades",
      SKey: { value: `crs#${crsId};`, optype: "begins" },
    };
    const resp = await axios.post(
      `${ApiMap.LMS_REST_ENDPOINT}/getitems`,
      postdata,
      AuthService.getAuthHeader()
    );
    return resp;
  }
  async getCourseStudents(crsId) {
    let postdata = {
      Name: "GetCourseStudents",
      IndexName: "index2",
      Key:`crs#${crsId};users`,
      Filters: {
        Filter1: {  
          name: "roleId",
          optype: "=",
          value: 1,
        },
      },
    };
    const resp = await axios.post(
      `${ApiMap.LMS_REST_ENDPOINT}/getitems`,
      postdata,
      AuthService.getAuthHeader()
    );
    return resp;
  }
  async getCourseAssignmentLessons(crsId) {
    let postdata = {
      Name: "GetCourseAssignmentLessons",
      IndexName: "index2",
      Key:`coursecontent`,
      SKey: { value: `crs#${crsId};`, optype: "begins" },
      Filters: {
        Filter1: {
          name: "isAssignment",
          optype: "=",
          value: true,
        },
        Filter2: {
          name: "openForStudent",
          optype: "=",
          value: true,
        }
      },
      FilterTemplate: "Filter1 AND Filter2"
    };
    const resp = await axios.post(
      `${ApiMap.LMS_REST_ENDPOINT}/getitems`,
      postdata,
      AuthService.getAuthHeader()
    );
    return resp;
  }
}

export default new CourseServices();
