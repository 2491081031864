import axios from "axios";
import jwt from "jsonwebtoken";

class AuthService {
  API_BASE_URL = process.env.REACT_APP_API_GATEWAY_URL;
  WSAPI_BASE_URL = process.env.REACT_APP_WSAPI_GATEWAY_URL;
  TOKEN_EXCH_KEY = process.env.REACT_APP_API_TOKEN_API_KEY;
  SIGNALR_URL = this.API_BASE_URL + "chatHub";
  USER_API_BASE_URL = this.API_BASE_URL + "user/";

  ACC_CREATE_ERR_MSG_FAILED = "Account creation failed";
  ACC_CREATE_ERR_MSG_INV_INP = "Invalid input";

  referringRoute() {
    /* var refRoute = localStorage.getItem("refRoute");
      console.log(refRoute);
   if (refRoute) {
      return "/" + refRoute;
    } */
    return "/Courses";
  }

  isLoggedIn() {
    if (!this.getUserInfo()) {
      return false;
    }
    var token = this.getUserInfo().token;
    if (!token) {
      return false;
    }
    var tokenexp = jwt.decode(token).exp * 1000;
    return tokenexp > new Date().getTime();
  }

  Role() {
    if (!this.getUserInfo()) {
      return null;
    }
    var token = this.getUserInfo().token;
    if (!token) {
      return null;
    }
    return jwt.decode(token).role;
  }

  isStudent(crsId) {
    const role = this.getUserRoleInCourse(crsId);
    if (!role) {
      return false;
    }
    return role["roleId"] == 1;
  }

  isFaculty(crsId) {
    const role = this.getUserRoleInCourse(crsId);
    if (!role) {
      return false;
    }
    return role["roleId"] == 2;
  }

  isCourseAdmin() {
    if (!this.getUserInfo()) {
      return false;
    }
    var token = this.getUserInfo().token;
    if (!token) {
      return false;
    }
    return jwt.decode(token).role === "CourseAdmin";
  }

  getUserCourses() {
    if (!this.getUserInfo()) {
      return null;
    }
    var token = this.getUserInfo().token;
    if (!token) {
      return null;
    }
    const userInfo = this.getUserInfo();
    return userInfo["mycourses"];
  }

  getUserRoleInCourse(crsId) {
    if (!this.getUserInfo()) {
      return null;
    }
    var token = this.getUserInfo().token;
    if (!token) {
      return null;
    }
    const userInfo = this.getUserInfo();
    const courses = userInfo["mycourses"];
    if (!courses) {
      return null;
    }
    let course = courses.filter(function (c) { return c.id == crsId });
    return course[0];
  };
  
  setAssignmentsDueCount(assignmentsDue) {
    var count = 0;
    if (assignmentsDue) {
      count = assignmentsDue.TotalAssignmentsDue;
    }
    var newuserinfo = this.getUserInfo();
    newuserinfo["assignmentsDue"] = count;
    localStorage.setItem("userInfo", JSON.stringify(newuserinfo));
  }

  getAssignmentsDueCount() {
    if (!this.getUserInfo()) {
      return 0;
    }
    return this.getUserInfo().assignmentsDue;
  }

  getLoggedInUserFnFl() {
    if (!this.getUserInfo()) {
      return null;
    }
    return this.getUserInfo().firstName.charAt(0);
  }
  getLoggedInUserFn() {
    if (!this.getUserInfo()) {
      return null;
    }
    return this.getUserInfo().firstName + " " + this.getUserInfo().lastName;
  }
  login(credentials) {
    return axios.post(this.USER_API_BASE_URL + "login", credentials);
  }

  getUserInfo() {
    return JSON.parse(localStorage.getItem("userInfo"));
  }

  getAuthToken() {
    return this.getUserInfo().token;
  }

  getAuthHeader() {
    return { headers: { Authorization: this.getUserInfo().token } };
  }

  logOut() {
    localStorage.removeItem("userInfo");
  }
}

export default new AuthService();
