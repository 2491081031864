import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";
import { Editor } from "@tinymce/tinymce-react";
import Grid from "@material-ui/core/Grid";
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    color: "#F5F5F5",
    backgroundColor: "#607D8B",
  },
  grow: { flexGrow: 1 },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    textAlign: "center",
  },
  form: {
    padding: "7px",
    margin: "1px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MCMAOptions({ options, updateCallback }) {
  function setOptionId(e, i) {
    options[i].id = parseInt(e.target.value);
    updateCallback(options, false);
  }

  function setOptionVal(e, i) {
    options[i].Value = e;
    updateCallback(options, false);
  }

  function setRationale(e, i) {
    options[i].Rationale = e.target.value;
    updateCallback(options, false);
  }

  function setOptionAnswer(e, i) {
    options[i].IsCorrect = e.target.checked;
    updateCallback(options, true);
  }

  function addOption() {
    var id = 1;
    if (options.length > 0) {
      id = options[options.length - 1].id + 1;
    }
    options.push({
      IsCorrect: false,
      id: id,
      Rationale: "",
      Value: "",
    });
    updateCallback(options, true);
  }

  function deleteOption(i) {
    options.splice(i, 1);
    updateCallback(options, true);
  }
  function Option({ opt, n }) {
    return (
      <Grid container key={opt.id} style={{ marginBottom: "3px" }}>
        <Grid item xs={3} sm={1} style={{ padding: "20px" }}>
          <DeleteIcon onClick={() => deleteOption(n)} color="secondary" />
        </Grid>
        <Grid item xs={4} sm={1} style={{ padding: "15px" }}>
          <FormControlLabel
            control={
              <Switch
                name="istrue"
                checked={opt.IsCorrect}
                onChange={(e) => setOptionAnswer(e, n)}
              />
            }
            label="True"
          />
        </Grid>
        <Grid item xs={5} sm={1} style={{ padding: "5px" }}>
          <TextField
            id="outlined-basic"
            label="Id"
            defaultValue={opt.id}
            variant="outlined"
            onChange={(e) => setOptionId(e, n)}
            fullWidth
            style={{ margin: 8, textAlign: "center" }}
          />
        </Grid>

        <Grid item xs={12} sm={5} style={{}}>
          <Editor
            apiKey="plgjmz2q4q6gougxbjnuycs8nmkg42fni1sne8bgj57ianzu"
            initialValue={opt.Value}
            init={{
              inline: true,
              menubar: false,
              plugins: ["link", "image", "media", "directionality"],
              toolbar:
                "bold italic underline fontsizeselect | ltr rtl | alignleft aligncenter alignright | forecolor backcolor removeformat | image media   link anchor codesample ",
              content_css: "//www.tiny.cloud/css/codepen.min.css",
              importcss_append: true,
              forced_root_block: "div",
            }}
            onEditorChange={(e) => setOptionVal(e, n)}
            style={{ marginBottom: "15px", border: "1px solid #AbAbaB" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ padding: "5px" }}>
          <TextField
            id="outlined-basic"
            label="Rationale"
            defaultValue={opt.Rationale}
            variant="outlined"
            fullWidth
            onChange={(e) => setRationale(e, n)}
            style={{ margin: 8 }}
          />
        </Grid>
      </Grid>
    );
  }
  var html = null;
  if (options && options.length > 0) {
    html = options.map(function (c, i) {
      return <Option opt={c} n={i}></Option>;
    });
  } else {
    options = [];
  }
  return (
    <>
      {html}
      <Grid container>
        <Grid item xs={12} style={{ textAlign: "left", paddingLeft: "35px" }}>
          <AddCircleIcon onClick={addOption} color="primary"></AddCircleIcon>
        </Grid>
      </Grid>
    </>
  );
}
