import React from "react";
import { Redirect } from "react-router-dom";
import AuthService from "../utils/AuthService";

function Authorized({children, refRoute}) {
  console.log("refRoute", refRoute);
  localStorage.setItem("refRoute", refRoute);
  if (!AuthService.isLoggedIn()) {
    return <Redirect to="/Signin" />;
  }
  return children;
}

export default Authorized;
